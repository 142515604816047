import { createRouter, createWebHashHistory } from "vue-router";
import SelfHome from "@/pages/SelfHome";
import PersonalManage from "@/pages/PersonalManage";
import CreateMission from "@/pages/CreateMission";
import CreatePost from "@/pages/CreatePost";
import CreateClub from "@/pages/CreateClub";
import MissionDetail from "@/pages/MissionDetail";
import ClubDetail from "@/pages/ClubDetail";
import CreateAssignment from "@/pages/CreateAssignment";
import EditProfile from "@/pages/EditProfile";
import FollowOther from "@/pages/FollowOther";
import MissionPay from "@/pages/MissionPay";
import MissionPaySuccess from "@/pages/MissionPaySuccess";
import MissionComplete from "@/pages/MissionComplete";
import WalletTransfers from "@/pages/WalletTransfers";
import WalletTransfersSuccess from "@/pages/WalletTransfersSuccess";
import MissionRank from "@/pages/MissionRank";
import { useMyStore } from "@/assets/store";
const $router = {
  install(app) {
    const { $particle, $loginModal, $tipModal,$http } =
      app.config.globalProperties;
    const { getInfoJudge} = useMyStore();
    const router = createRouter({
      history: createWebHashHistory(),
      routes: [
        {
          name: "SelfHome",
          path: "",
          component: SelfHome,
        },
        {
          name: "PersonalManage",
          path: "/personal-manage",
          component: PersonalManage,
        },
        {
          name: "CreateMission",
          path: "/create-mission",
          component: CreateMission,
        },
        {
          name: "CreatePost",
          path: "/create-post",
          component: CreatePost,
        },
        {
          name: "CreateClub",
          path: "/create-club",
          component: CreateClub,
        },
        {
          name: "MissionDetail",
          path: "/mission-detail",
          component: MissionDetail,
        },
        {
          name: "MissionPay",
          path: "/mission-pay",
          component: MissionPay,
        },
        {
          name: "MissionRank",
          path: "/mission-rank",
          component: MissionRank,
        },
        {
          name: "MissionPaySuccess",
          path: "/mission-pay-success",
          component: MissionPaySuccess,
        },
        {
          name: "MissionComplete",
          path: "/mission-complete",
          component: MissionComplete,
        },
        {
          name: "WalletTransfers",
          path: "/wallet-transfers",
          component: WalletTransfers,
        },
        {
          name: "WalletTransfersSuccess",
          path: "/wallet-transfers-success",
          component: WalletTransfersSuccess,
        },
        {
          name: "ClubDetail",
          path: "/club-detail",
          component: ClubDetail,
        },
        {
          name: "CreateAssignment",
          path: "/create-assignment",
          component: CreateAssignment,
        },
        {
          name: "EditProfile",
          path: "/edit-profile",
          component: EditProfile,
        },
        {
          name: "FollowOther",
          path: "/follow-other",
          component: FollowOther,
        },
      ],
    });
    router.beforeEach((to, from, next) => {
      // 登录页，首页，分享页都可以直接进
      if (to.path !== "/login" && to.name !== "SelfHome" && !to.query.shared) {
        if (from.name !== "SelfHome") {
          if ($particle.auth.isLogin()) {
            getInfoJudge($http ,$particle).then(() => {
              next();
            });
          } else {
            $loginModal({
              visible: true,
              next: next,
            });
          }
        } else {
          const method = from.matched[0].instances.default;
          // 首页判断有没有编辑草稿，如果有且有登录则提示保存，没登陆就出登陆界面
          if (method.$refs.editDraftPost?.judgeEditinfo()) {
            if ($particle.auth.isLogin()) {
              method.$refs.editDraftPost.next = next;
              $tipModal({
                visible: true,
                tipObj: {
                  title: "Whoa,hold up!",
                  content:
                    "Leaving the page will lose the draft, choose to save the draft first",
                  footer: [
                    {
                      name: "Cancel",
                      type: undefined,
                      query: "cancel",
                      class: "default-button",
                      fn: method.$refs.editDraftPost.receive,
                    },
                    {
                      name: "Save",
                      type: "primary",
                      query: "save",
                      class: "primary-button",
                      fn: method.$refs.editDraftPost.receive,
                    },
                  ],
                },
              });
            } else {
              $loginModal({
                visible: true,
                next: null,
              });
            }
          } else {
            // 如果没有且有登录则直接跳转，没登陆则出登陆界面
            if ($particle.auth.isLogin()) {
              getInfoJudge($http ,$particle).then(() => {
                next();
              });
            } else {
              $loginModal({
                visible: true,
                next: next,
              });
            }
          }
        }
      } else {
        getInfoJudge($http ,$particle).then(() => {
          next();
        });
      }
    });

    app.use(router);
  },
};

export default $router;
