export default [{
    name: "Brunei",
    value: "096",
    img: "1.png"
}, {
    name: "Myanmar",
    value: "104",
    img: "2.png"
}, {
    name: "Cambodia",
    value: "116",
    img: "3.png"
}, {
    name: "Timor-Leste",
    value: "626",
    img: "4.png"
}, {
    name: "Indonesia",
    value: "360",
    img: "5.png"
}, {
    name: "Laos",
    value: "418",
    img: "6.png"
}, {
    name: "Malaysia",
    value: "458",
    img: "7.png"
}, {
    name: "Philippines",
    value: "608",
    img: "8.png"
}, {
    name: "Singapore",
    value: "702",
    img: "9.png"
}, {
    name: "Thailand",
    value: "764",
    img: "10.png"
}, {
    name: "Vietnam",
    value: "704",
    img: "11.png"
}, {
    name: "Others",
    value: "000",
    img: "12.png"
}]