import "core-js/modules/es.array.push.js";
export default {
  name: "MissionPay",
  data() {
    return {
      id: "",
      payment: "",
      detail: null
    };
  },
  computed: {},
  watch: {},
  methods: {
    join() {
      if (!this.payment) {
        this.$tipModal({
          visible: true,
          tipObj: {
            title: "",
            content: "Please select mode of payment",
            footer: [{
              name: "Close",
              type: undefined,
              query: "close",
              class: "default-button"
            }]
          }
        });
        return;
      }
      const params = {
        missionId: this.id,
        payMethodWeb2Enum: this.payment === "funtana" ? "BALANCE" : "FOMO"
      };
      this.$http.post(`/api/mission/payJoin`, params).then(({
        data: res
      }) => {
        if (res.success) {
          // 余额支付直接返回结果，fomo支付得到的是支付超链接给用户
          if (this.payment === "funtana") {
            this.$router.push({
              name: "MissionPaySuccess",
              query: {
                payId: res.data,
                origin: "joined"
              }
            });
          } else {
            window.open(res.data, "_self");
          }
        }
      }).catch(() => {});
    },
    // 返回
    back() {
      this.$router.back();
    },
    getDetail() {
      const params = {
        timeZone: this.$timezone,
        missionId: this.id
      };
      this.$http({
        url: "/api/mission/detail",
        //请求的后台接口
        method: "get",
        //get请求方式
        params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.detail = res.data;
        }
      }).catch(() => {});
    }
  },
  created() {
    this.id = this.$route.query?.missionId || "";
    this.getDetail();
  }
};