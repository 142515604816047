import "core-js/modules/es.array.push.js";
import PostData from "./PostData.vue";
import SelfComment from "./SelfComment.vue";
export default {
  name: "PostLike",
  props: ["index"],
  components: {
    PostData,
    SelfComment
  },
  data() {
    return {
      menu: [{
        name: "All posts",
        total: 0,
        search: {
          filter: "All",
          query: "",
          origin: "post"
        },
        filterList: [{
          name: "All"
        },
        // {
        //   name: "Selected",
        //   divided: true,
        // },
        {
          name: "Private",
          divided: true
        }, {
          name: "Public"
        }],
        filterTitle: "Filter",
        ref: "postData"
      }, {
        search: {
          filter: "Post",
          origin: "like"
        },
        filterList: [{
          name: "Post"
        }, {
          name: "Submission"
        }],
        filterTitle: "Filter",
        ref: "likeData"
      }, {
        search: {
          filter: "My comment",
          origin: "comment"
        },
        filterList: [{
          name: "My comment"
        }, {
          name: "Reply to me"
        }],
        filterTitle: "Filter",
        ref: "SelfComment"
      }]
    };
  },
  computed: {
    menuIndex() {
      return this.index === "6" ? 0 : this.index === "7" ? 1 : 2;
    }
  },
  methods: {
    // 选中filter菜单
    chooseFilter($event) {
      this.menu[this.menuIndex].search.filter = $event;
      this.getData();
    },
    getData() {
      this.$refs[`${this.menu[this.menuIndex].ref}`].getData(true);
    },
    // 获取查询总数
    receiveTotal($event) {
      this.menu[this.menuIndex].total = $event.value;
    },
    editProfile() {
      this.$router.push({
        name: "EditProfile"
      }).catch(() => {});
    },
    toOther() {
      if (this.info.id !== this.info.id) {
        this.$router.push({
          name: "FollowOther",
          query: {
            userId: this.info.id
          }
        });
      } else {
        this.$router.push({
          name: "PersonalManage",
          query: {
            index: "3"
          }
        });
      }
    }
  },
  created() {}
};