import MissionData from "../components/MissionData.vue";
import ClubData from "../components/ClubData.vue";
import PersonAssign from "../components/PersonAssign.vue";
import PostData from "../components/PostData.vue";
export default {
  name: "FollowOther",
  components: {
    MissionData,
    ClubData,
    PersonAssign,
    PostData
  },
  data() {
    return {
      menu: [{
        name: "Mission",
        total: 0,
        search: {
          filter: "All",
          query: "",
          origin: "otherMission"
        },
        filterList: [{
          name: "All"
        }, {
          name: "In progress",
          divided: true
        }, {
          name: "Done"
        }],
        filterTitle: "Filter",
        ref: "missionData"
      }, {
        name: "Club",
        total: 0,
        search: {
          filter: "All",
          query: "",
          origin: "otherClub"
        },
        ref: "clubData"
      }, {
        name: "Submissions",
        total: 0,
        search: {
          filter: "All",
          query: "",
          origin: "otherAssignments"
        },
        filterList: [{
          name: "All"
        }, {
          name: "Date modified",
          divided: true
        }],
        filterTitle: "Sort",
        ref: "assignData"
      }, {
        name: "Posts",
        total: 0,
        search: {
          filter: "Public",
          query: "",
          origin: "otherPosts"
        },
        ref: "postData"
      }],
      menuIndex: 0,
      // 列表展示方式-card,list
      showType: "card",
      otherInfo: null,
      userId: ""
    };
  },
  methods: {
    getOtherInfo() {
      this.$http({
        url: "/api/auth/userInfo?userId=" + this.userId,
        //请求的后台接口
        method: "get" //get请求方式
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.otherInfo = res.data;
        }
      }).catch(() => {});
    },
    // 改变数据展示方式
    changeShowType(type) {
      this.showType = type;
      // this.getData();
    },
    // 选中filter菜单
    chooseFilter($event, item) {
      item.search.filter = $event;
      this.getData();
    },
    getData() {
      this.$refs[`${this.menu[this.menuIndex].ref}`].getData(true);
    },
    // 获取查询总数
    receiveTotal($event) {
      // 用于初始化调全部页面接口接收总数
      if ($event.name === "mission") {
        this.menu[0].total = $event.value;
      } else if ($event.name === "club") {
        this.menu[1].total = $event.value;
      } else if ($event.name === "assign") {
        this.menu[2].total = $event.value;
      } else if ($event.name === "post") {
        this.menu[3].total = $event.value;
      }
    },
    changeMenuIndex(index) {
      if (index === 3 && this.showType === "list") {
        this.showType = "card";
      }
      this.menuIndex = index;
      this.getData();
    },
    followOther() {
      if (this.isLogin()) {
        return;
      }
      const url = this.otherInfo.followedByMe ? "api/friendShip/unfollow" : "api/friendShip/follow";
      this.$http.post("/" + url + "?userId=" + this.userId).then(({
        data: res
      }) => {
        if (res.success) {
          this.getOtherInfo();
        }
      }).catch(() => {});
    },
    back() {
      this.$router.back();
      sessionStorage.removeItem("otherInfo");
    }
  },
  created() {
    this.userId = this.$route.query.userId;
    sessionStorage.setItem("otherInfo", this.userId);
    this.getOtherInfo();
  },
  unmounted() {
    sessionStorage.removeItem("otherInfo");
  }
};