import "core-js/modules/es.array.push.js";
import CommentAll from "@/components/CommentAll.vue";
export default {
  name: "PostCard",
  props: ["item", "searchInfo"],
  components: {
    CommentAll
  },
  data() {
    return {
      showComment: false,
      commentObj: null
    };
  },
  methods: {
    componentMenu($event, item) {
      this.$emit("componentMenu", {
        $event,
        item
      });
    },
    privatePublic(item) {
      this.$http.post("/api/post/togglePubReadAccess?postId=" + item.id).then(({
        data: res
      }) => {
        if (res.success) {
          item.tagCanPub = item.tagCanPub === "0" ? "1" : "0";
        }
      }).catch(() => {});
    },
    // 关注
    followOther(item) {
      if (this.isLogin()) {
        return;
      }
      const url = item.followedByMe ? "api/friendShip/unfollow" : "api/friendShip/follow";
      this.$http.post("/" + url + "?userId=" + item.userId).then(({
        data: res
      }) => {
        if (res.success) {
          item.followedByMe = !item.followedByMe;
        }
      }).catch(() => {});
    },
    showCommentModal() {
      this.showComment = true;
      this.commentObj = {
        id: this.item.id,
        origin: this.searchInfo?.origin === "like" && this.searchInfo?.filter === "Submission" ? "assignment" : "post"
      };
    },
    toOther(item) {
      if (this.isLogin()) {
        return;
      }
      if (item.userId !== this.info.id) {
        this.$router.push({
          name: "FollowOther",
          query: {
            userId: item.userId
          }
        });
      } else {
        this.$router.push({
          name: "PersonalManage",
          query: {
            index: "3"
          }
        });
      }
    },
    dianzan(item, type) {
      if (this.isLogin()) {
        return;
      }
      const url = type === "like" ? this.searchInfo?.filter.toLowerCase() : type;
      this.clickLike(item, url);
    },
    updateLayout() {
      this.$emit("updateLayout");
    }
  }
};