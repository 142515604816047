import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-ed20343e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  id: "self-comment"
};
const _hoisted_2 = {
  class: "mission-row",
  "infinite-scroll-disabled": "disabled",
  "infinite-scroll-distance": "400"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  class: "follow-card-top"
};
const _hoisted_5 = {
  style: {
    "flex": "1"
  }
};
const _hoisted_6 = {
  class: "user-title text-ellipsis"
};
const _hoisted_7 = {
  class: "user-lock"
};
const _hoisted_8 = {
  class: "top-icon"
};
const _hoisted_9 = {
  class: "follow-card-one"
};
const _hoisted_10 = {
  class: "ellipsis",
  style: {
    "white-space": "pre-wrap"
  }
};
const _hoisted_11 = {
  class: "comment-user"
};
const _hoisted_12 = {
  class: "comment-user-content ellipsis"
};
const _hoisted_13 = ["onClick"];
const _hoisted_14 = {
  key: 0,
  class: "icon iconfont icon-aixin"
};
const _hoisted_15 = {
  key: 1,
  class: "icon iconfont icon-xiai",
  style: {
    "color": "#fc4f00"
  }
};
const _hoisted_16 = {
  key: 0,
  class: "scroll-text"
};
const _hoisted_17 = {
  key: 1,
  class: "scroll-text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_avatar = _resolveComponent("el-avatar");
  const _component_Delete = _resolveComponent("Delete");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_ChatDotRound = _resolveComponent("ChatDotRound");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_CommentAll = _resolveComponent("CommentAll");
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.mission, item => {
    return _openBlock(), _createElementBlock("div", {
      class: "mission-col",
      key: item.id
    }, [_createElementVNode("div", {
      class: "mbc-card",
      onClick: $event => $options.showCommentModal(item)
    }, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_avatar, {
      fit: "cover",
      size: "large",
      src: item.fromUserAvatar,
      style: {
        "flex-shrink": "0"
      },
      onClick: _withModifiers($event => $options.toOther(item, 'fromUserId'), ["stop"])
    }, null, 8, ["src", "onClick"]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString(item.fromUserName), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(item.fromUserId !== _ctx.info.id ? "Replies to your comment" : "Your comment"), 1)]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_el_icon, {
      onClick: _withModifiers($event => $options.showDel(item), ["stop"])
    }, {
      default: _withCtx(() => [_createVNode(_component_Delete)]),
      _: 2
    }, 1032, ["onClick"])])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("p", _hoisted_10, _toDisplayString(item.content), 1), _createElementVNode("div", _hoisted_11, [_createVNode(_component_el_avatar, {
      fit: "cover",
      size: "medium",
      src: item.objUserAvatar,
      onClick: _withModifiers($event => $options.toOther(item, 'toUserId'), ["stop"])
    }, null, 8, ["src", "onClick"]), _createElementVNode("div", _hoisted_12, _toDisplayString(item.objTitle), 1), item.objFirstImageUrl ? (_openBlock(), _createBlock(_component_el_avatar, {
      key: 0,
      fit: "cover",
      shape: "square",
      size: "medium",
      src: item.objFirstImageUrl
    }, null, 8, ["src"])) : _createCommentVNode("", true)])]), _createVNode(_component_el_row, {
      class: "self-person-footer"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 12,
        class: "self-footer-num"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_icon, null, {
          default: _withCtx(() => [_createVNode(_component_ChatDotRound)]),
          _: 1
        }), _createTextVNode("Reply "), _createElementVNode("span", {
          onClick: _withModifiers($event => _ctx.clickLike(item, 'comment'), ["stop"])
        }, [!item?.likedByMe ? (_openBlock(), _createElementBlock("i", _hoisted_14)) : _createCommentVNode("", true), item?.likedByMe ? (_openBlock(), _createElementBlock("i", _hoisted_15)) : _createCommentVNode("", true)], 8, _hoisted_13)]),
        _: 2
      }, 1024), _createVNode(_component_el_col, {
        span: 12,
        Align: "right"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(item.createDateStr), 1)]),
        _: 2
      }, 1024)]),
      _: 2
    }, 1024)], 8, _hoisted_3)]);
  }), 128))])), [[_directive_infinite_scroll, $options.load]]), $data.loading ? (_openBlock(), _createElementBlock("p", _hoisted_16, "loading...")) : _createCommentVNode("", true), $options.noMore ? (_openBlock(), _createElementBlock("p", _hoisted_17, "No more")) : _createCommentVNode("", true), $data.showComment ? (_openBlock(), _createBlock(_component_CommentAll, {
    key: 2,
    obj: $data.commentObj,
    onCommentBack: _cache[0] || (_cache[0] = $event => $data.showComment = false)
  }, null, 8, ["obj"])) : _createCommentVNode("", true)]);
}