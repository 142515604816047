import "core-js/modules/es.array.push.js";
import MissionClub from "@/components/MissionClub.vue";
import SelfPerson from "@/components/SelfPerson.vue";
import PostLike from "@/components/PostLike.vue";
export default {
  name: "PersonalManage",
  components: {
    MissionClub,
    SelfPerson,
    PostLike
  },
  data() {
    return {
      // 导航栏定位
      index: ""
    };
  },
  methods: {
    // 选中左侧导航栏回调
    changeIndex($event) {
      this.index = $event;
      if (this.index === "1") {
        this.$router.push({
          name: "SelfHome"
        }).catch(() => {});
      } else if (this.index === "4-1") {
        this.$router.push({
          name: "CreateMission"
        }).catch(() => {});
      } else if (this.index === "4-2") {
        this.$router.push({
          name: "CreateClub"
        }).catch(() => {});
      } else if (this.index === "4-3") {
        this.$router.push({
          name: "CreatePost"
        }).catch(() => {});
      } else if (this.index === "4-4") {
        this.$router.push({
          name: "CreateAssignment"
        }).catch(() => {});
      } else {
        this.$router.push({
          name: "PersonalManage",
          query: {
            index: this.index
          }
        }).catch(() => {});
      }
    },
    // 回到顶部
    scrollTop() {
      const dom = this.index === "2-1" || this.index === "2-2" || this.index === "4" ? "missionClub" : this.index === "3" ? "selfPerson" : "postLike";
      this.$refs[`${dom}`].$refs.scrollContainerRef.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  },
  created() {
    // 接收路由传过来的index展示对应导航栏
    this.index = this.$route.query?.index || "";
  }
};