import country from "@/assets/utils/country";
export default {
  name: "EditProfile",
  data() {
    return {
      otherInfo: {
        id: "",
        avatar: "",
        firstName: "",
        lastName: "",
        userName: "",
        aboutMe: "",
        region: "",
        gender: "",
        phone: ""
      },
      countryList: [],
      countryName: "",
      countryImg: ""
    };
  },
  methods: {
    // 上传文件成功后将其添加至fileList
    upload(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      this.$http.post("/api/file/fileUpload", formData).then(({
        data: res
      }) => {
        if (res.success) {
          this.otherInfo.avatar = res.data.url;
        }
      }).catch(() => {});
    },
    beforeUpload(file) {
      if (file.type.indexOf("image") > -1) {
        return true;
      } else {
        return false;
      }
    },
    chooseCountry($event) {
      this.otherInfo.region = $event.value;
      this.countryName = $event.name;
      this.countryImg = require("../assets/country/" + $event.img);
    },
    // 返回
    back() {
      this.$router.back();
    },
    save() {
      this.$http.post("/api/auth/editProfile", this.otherInfo).then(({
        data: res
      }) => {
        if (res.success) {
          sessionStorage.removeItem("info");
          this.back();
        }
      }).catch(() => {});
    }
  },
  created() {
    this.countryList = country;
    for (const key in this.otherInfo) {
      if (Object.hasOwnProperty.call(this.otherInfo, key)) {
        this.otherInfo[key] = this.info[key];
      }
    }
    if (this.otherInfo.region) {
      this.countryName = this.countryList.find(e => e.value === this.otherInfo.region).name;
      this.countryImg = this.info.countryImg;
    }
  }
};