import { createApp } from "vue";
import LoginComponents from "@/components/LoginComponents";
import { useMyStore } from "@/assets/store";

const MyLoginComponents = {
  install(app) {
    // 配置此应用
    app.config.globalProperties.$loginModal = function (options) {
      const container = document.createElement("div");
      const { getInfoJudge } = useMyStore();
      let vm = createApp(LoginComponents, {
        ...options,
        particle: app.config.globalProperties.$particle,
        http: app.config.globalProperties.$http,
        getInfoJudge: getInfoJudge,
        close: () => {
          if (vm) {
            vm.unmount();
            document.body.removeChild(container);
            vm = undefined;
          }
        },
      });

      document.body.appendChild(container);
      vm.mount(container);
    };
  },
};
export default MyLoginComponents;
