export default {
  name: "ChooseModal",
  props: ["radioClub"],
  data() {
    return {
      club: [],
      radioClubFmt: this.radioClub
    };
  },
  methods: {
    changeClub(value) {
      this.radioClubFmt = value;
    },
    hidden(type) {
      this.$emit("changeClub", type == "cancel" ? this.radioClub : this.radioClubFmt);
    },
    // 获取club列表
    getClubData() {
      const params = {
        userId: this.info.id,
        timeZone: this.$timezone,
        name: "",
        page: 1,
        size: 99999
      };
      this.$http({
        url: "/api/club/listFollowed",
        //请求的后台接口
        method: "get",
        //get请求方式
        params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.club = res.data.list;
          if (!this.club.length) {
            this.$tipModal({
              visible: true,
              tipObj: {
                title: "",
                content: "You haven't joined a club before, you can create or join a new one",
                footer: [{
                  name: "Close",
                  type: undefined,
                  query: "close",
                  class: "default-button"
                }
                // {
                //   name: "Create a club",
                //   type: "primary",
                //   query: "createClub",
                //   class: "primary-button",
                //   fn: () => {
                //     this.$router
                //       .push({
                //         name: "CreateClub",
                //       })
                //       .catch(() => {});
                //   },
                // },
                ]
              }
            });
          }
        }
      }).catch(() => {});
    }
  },
  created() {
    this.getClubData();
  }
};