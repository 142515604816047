import "core-js/modules/es.array.push.js";
export default {
  name: "WalletTransfers",
  data() {
    return {
      type: "all",
      // all,topUp,withdraw,payment,detail,confirm展示页面
      accountDetail: null,
      // 资金情况
      visible: true,
      // 金额是否可以查看
      // 交易记录
      list: [],
      pageTotal: 0,
      pageIndex: 1,
      paymentMethod: "",
      // 支付方式
      detail: null,
      // 详情
      // 转账的人
      userPayment: null,
      userList: [],
      showWithdrawModal: false,
      // 提现数据
      institutionName: "",
      institutionAccount: "",
      loading: false,
      cancel: null
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 返回
    back() {
      if (this.type === "all") {
        this.$router.push({
          name: "SelfHome"
        });
      } else {
        this.type = "all";
      }
    },
    // 查询账户信息
    getAccountDetail() {
      this.$http({
        url: "/api/account/getMyDetail",
        //请求的后台接口
        method: "get" //get请求方式
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.accountDetail = res.data;
        }
      }).catch(() => {});
    },
    // 查询历史记录
    getList() {
      const params = {
        timeZone: this.$timezone,
        page: this.pageIndex,
        size: 10
      };
      this.$http({
        url: "/api/account/listMyTransaction",
        //请求的后台接口
        method: "get",
        //get请求方式
        params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.list = res.data.list;
          this.pageTotal = res.data.totalCount;
        }
      }).catch(() => {});
    },
    // 查询最近转账的人
    getUserList(value) {
      if (this.cancel) {
        this.cancel();
      }
      const that = this;
      const CancelToken = this.$http.CancelToken;
      this.loading = true;
      const params = {
        page: 1,
        size: 9999,
        keyword: value
      };
      this.$http({
        url: "/api/auth/searchUser",
        //请求的后台接口
        method: "get",
        //get请求方式
        params,
        config: {
          disabledLoading: true
        },
        cancelToken: new CancelToken(function executor(c) {
          // 将axios的取消函数赋值给外部变量
          that.cancel = c;
        })
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.userList = res.data.list;
        } else {
          this.userList = [];
        }
        this.cancel = null;
        this.loading = false;
      }).catch(() => {});
    },
    // 充值
    topUp() {
      this.detail = {
        way: "Top up",
        recipient: "Funtana Wallet",
        paymentMethod: this.paymentMethod,
        date: this.moment(new Date()).tz(this.$timezone).format("yyyy-MM-DD HH:mm:ss"),
        money: this.num
      };
      this.type = "confirm";
    },
    // 转账
    payment() {
      this.detail = {
        way: "Payment",
        recipient: this.userPayment?.userName,
        paymentMethod: "Funtana Wallet",
        date: this.moment(new Date()).tz(this.$timezone).format("yyyy-MM-DD HH:mm:ss"),
        money: this.num
      };
      this.type = "confirm";
    },
    // 提现
    withdraw() {
      if (!this.institutionName || !this.institutionAccount) {
        this.$tipModal({
          visible: true,
          tipObj: {
            title: "",
            content: "Please maintain the account where you withdraw your payouts",
            footer: [{
              name: "Close",
              type: undefined,
              query: "close",
              class: "default-button"
            }]
          }
        });
        return;
      }
      this.$http({
        url: `/api/account/withdrawConfirm?amount=${this.num}`,
        //请求的后台接口
        method: "get" //get请求方式
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.detail = {
            way: "Withdraw",
            recipient: this.institutionName,
            paymentMethod: this.institutionAccount,
            date: this.moment(new Date()).tz(this.$timezone).format("yyyy-MM-DD HH:mm:ss"),
            money: this.num,
            feeRatio: res.data.feeRatio,
            fee: res.data.fee,
            totalAmount: res.data.totalAmount
          };
          this.type = "confirm";
        }
      }).catch(() => {});
    },
    confirm() {
      if (this.detail?.way === "Top up") {
        const params = {
          amount: this.num
        };
        this.$http({
          method: "post",
          url: `/api/account/topUp`,
          data: params
        }).then(({
          data: res
        }) => {
          if (res.success) {
            window.open(res.msg, "_self");
          }
        }).catch(() => {});
      } else if (this.detail?.way === "Payment") {
        const params = {
          amount: this.num,
          receiveUserId: this.userPayment.id,
          payMethod: "BALANCE"
        };
        this.$http({
          method: "post",
          url: `/api/account/transfer`,
          data: params
        }).then(({
          data: res
        }) => {
          if (res.success) {
            this.goTo(res.data);
          }
        }).catch(() => {});
      } else if (this.detail?.way === "Withdraw") {
        const params = {
          amount: this.num,
          institutionName: this.institutionName,
          institutionAccount: this.institutionAccount
        };
        this.$http({
          method: "post",
          url: `/api/account/withdraw`,
          data: params
        }).then(({
          data: res
        }) => {
          if (res.success) {
            this.goTo(res.data);
          }
        }).catch(() => {});
      }
    },
    goTo(id) {
      this.$router.push({
        name: "WalletTransfersSuccess",
        query: {
          payId: id
        }
      });
    },
    changeType(type) {
      this.type = type;
    },
    getTransactionDetail(item) {
      const params = {
        billId: item.id,
        timeZone: this.$timezone
      };
      this.$http({
        url: "/api/account/getTransactionDetail",
        //请求的后台接口
        method: "get",
        //get请求方式
        params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.detail = res.data;
        }
      }).catch(() => {});
    }
  },
  created() {
    this.getAccountDetail();
    this.getList();
  }
};