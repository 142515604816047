import "core-js/modules/es.array.push.js";
export default {
  name: "SelfPicker",
  props: ["end", "start", "selectedTimezone"],
  data() {
    return {
      startDate: "",
      endDate: "",
      yearMonth: "",
      // 当前展示日期年月
      isCrossMonth: false,
      // 是否跨月
      weekDays: []
    };
  },
  computed: {
    startYearMonth() {
      return this.startDate ? this.moment(this.startDate, "yyyy-MM-DD").format("yyyy-MM") : "";
    },
    endYearMonth() {
      return this.endDate ? this.moment(this.endDate, "yyyy-MM-DD").format("yyyy-MM") : "";
    },
    startChooseDay() {
      return this.startDate ? this.moment(this.startDate, "yyyy-MM-DD").format("DD") : "";
    },
    endChooseDay() {
      return this.endDate ? this.moment(this.endDate, "yyyy-MM-DD").format("DD") : "";
    },
    yearMonthShow() {
      return this.yearMonth ? this.moment(this.yearMonth, "yyyy-MM-01").format("MMM yyyy") : '';
    }
  },
  watch: {
    start: {
      immediate: true,
      //初始化时让handler调用一下
      handler(newValue) {
        this.startDate = newValue;
        if (this.startDate && !this.endDate) {
          this.yearMonth = this.moment(this.startDate, "yyyy-MM-DD").format("yyyy-MM");
          this.calendar(this.startDate);
        }
      }
    },
    end: {
      immediate: true,
      //初始化时让handler调用一下
      handler(newValue) {
        this.endDate = newValue;
        if (this.startDate && this.endDate) {
          this.yearMonth = this.moment(this.endDate, "yyyy-MM-DD").format("yyyy-MM");
          this.calendar(this.endDate);
        }
      }
    }
  },
  methods: {
    // 月份展示
    calendar(setDate) {
      // 星期几部分
      const date = new Date(setDate);
      const y = date.getFullYear();
      const m = date.getMonth();
      const list = [];
      // 判断该月有多少天
      for (let i = 1; i < new Date(y, m + 1, 0).getDate() + 1; i++) {
        list.push(i < 10 ? "0" + i : "" + i);
      }
      // 判断第一天是星期几，push到哪个位置
      for (let i = 0; i < new Date(y, m, 1).getDay(); i++) {
        list.unshift("");
      }
      this.weekDays = [];
      for (let i = 0; i < list.length; i += 7) {
        this.weekDays.push(list.slice(i, i + 7));
      }
    },
    // 选择日期
    choose(item) {
      if (item) {
        if (!this.startDate) {
          // 1. 未选择开始日期 => 开始日期 = 选中的日期
          // this.isCrossMonth = false;
          this.startDate = this.yearMonth + "-" + item;
        } else if (this.startDate && !this.endDate) {
          // 2.选择了开始日期 未选结束日期
          if (this.yearMonth === this.startYearMonth) {
            // 未跨月
            this.isCrossMonth = false;
            if (item < this.startChooseDay) {
              // 2.1 选中的日期 小于开始日期  => 开始日期 = 选中的日期
              this.startDate = this.yearMonth + "-" + item;
            } else {
              //  2.2 选中的日期 大于等于开始日期  => 结束日期 = 选中的日期
              this.endDate = this.yearMonth + "-" + item;
            }
          } else {
            // 跨月
            this.isCrossMonth = true;
            const end = this.yearMonth + "-" + item;
            if (new Date(end).getTime() < new Date(this.startDate).getTime()) {
              // 跨月 开始年月大于当前所选开始日期年月
              this.startDate = this.yearMonth + "-" + item;
              this.isCrossMonth = false;
            } else {
              // 跨月 开始年月小于当前所选年月
              this.endDate = this.yearMonth + "-" + item;
            }
          }
        } else if (this.startDate && this.endDate) {
          // 3. 开始日期 和 结束日期 都选择了 => 开始日期 = 选中的日期; 结束日期 = null
          this.isCrossMonth = false;
          this.startDate = this.yearMonth + "-" + item;
          this.endDate = "";
        }
        this.$emit("changeDate", {
          start: this.startDate,
          end: this.endDate
        });
      }
    },
    // 月份改变
    yearMonthChange(type) {
      const currentDate = new Date(this.yearMonth + "-01");
      const nextDate = type === "plus" ? currentDate.setMonth(currentDate.getMonth() + 1) : currentDate.setMonth(currentDate.getMonth() - 1); // 输出日期格式为毫秒形式1556668800000
      const nextDates = new Date(nextDate);
      const nextYear = nextDates.getFullYear();
      const currentMonth = nextDates.getMonth() + 1;
      const nextMonth = currentMonth < 10 ? "0" + currentMonth : currentMonth; // 因日期中的月份表示为0-11，所以要显示正确的月份，需要 + 1
      this.yearMonth = nextYear + "-" + nextMonth; // "2019-05"
      this.calendar(this.yearMonth + "-01");
    }
  },
  created() {
    this.yearMonth = this.moment(this.endDate, "yyyy-MM-DD").format("yyyy-MM");
    this.calendar(this.endDate);
  }
};