import "core-js/modules/es.array.push.js";
export default {
  name: "CreateAssignment",
  data() {
    return {
      fileList: [],
      linkUrl: "",
      title: "",
      description: "",
      missionId: "",
      mission: [],
      id: ""
    };
  },
  computed: {
    limit() {
      return this.mission.find(e => this.missionId === e.id)?.characterNumLimit || null;
    }
  },
  methods: {
    // 删除图片
    handleRemove(file) {
      const index = this.fileList.findIndex(object => {
        return object.url === file.url;
      });
      this.fileList.splice(index, 1);
    },
    // 上传文件成功后将其添加至fileList
    upload(file) {
      // this.fileList.push(file);
      const formData = new FormData();
      formData.append("file", file.file);
      this.$refs.uploadFile.clearFiles();
      this.$http.post("/api/file/fileUpload", formData).then(({
        data: res
      }) => {
        if (res.success) {
          this.fileList.push({
            name: res.data.fileName,
            url: res.data.url,
            // 鼠标悬浮图片上面出现删除按钮
            imageDelete: false
          });
        }
      }).catch(() => {});
    },
    beforeUpload(file) {
      if (file.type.indexOf("image") > -1) {
        return true;
      } else {
        return false;
      }
    },
    getMissionData() {
      const params = {
        timeZone: this.$timezone,
        userId: this.info.id,
        tagClose: "0",
        title: "",
        page: 1,
        size: 99999
      };
      this.$http({
        url: "/api/mission/listJoined",
        //请求的后台接口
        method: "get",
        //get请求方式
        params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.mission = res.data.list.filter(e => this.missionId === e.id);
        }
      }).catch(() => {});
    },
    // 返回
    back() {
      this.$router.back();
    },
    tip(content) {
      this.$tipModal({
        visible: true,
        tipObj: {
          title: "",
          content,
          footer: [{
            name: "Close",
            type: undefined,
            query: "close",
            class: "default-button"
          }]
        }
      });
    },
    save() {
      if (!this.title.trim()) {
        this.tip("Please maintain the title");
        return;
      }
      if (!this.description.trim()) {
        this.tip("Please maintain the description");
        return;
      }
      if (!this.missionId) {
        this.tip("Please maintain the mission");
        return;
      }
      const imageUrl = this.fileList.map(e => e.url);
      if (this.linkUrl) {
        imageUrl.push(this.linkUrl);
      }
      // if (!imageUrl.length) {
      //   this.$tipModal.show({
      //     title: "",
      //     content: "Please maintain the image",
      //     footer: [
      //       {
      //         name: "Close",
      //         type: "",
      //         query: "close",
      //       },
      //     ],
      //   });
      //   return;
      // }
      const params = {
        id: this.id || undefined,
        title: this.title.trim(),
        description: this.description.trim(),
        imageUrls: imageUrl,
        missionId: this.missionId
      };
      const url = this.id ? "api/assignment/updateOne" : "api/assignment/create";
      this.$http.post("/" + url, params).then(({
        data: res
      }) => {
        if (res.success) {
          this.back();
        }
      }).catch(() => {});
    },
    getDetail() {
      const params = {
        timeZone: this.$timezone,
        assignmentId: this.id
      };
      this.$http({
        url: "/api/assignment/getDetail",
        //请求的后台接口
        method: "get",
        //get请求方式
        params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          const {
            title,
            description,
            missionId,
            imageUrls
          } = res.data;
          this.title = title;
          this.description = description;
          this.missionId = missionId;
          this.fileList = imageUrls.map(e => {
            return {
              name: "assign.png",
              url: e,
              // 鼠标悬浮图片上面出现删除按钮
              imageDelete: false
            };
          });
        }
      }).catch(() => {});
    }
  },
  created() {
    this.id = this.$route.query?.id || "";
    this.missionId = this.$route.query?.missionId || "";
    this.getMissionData();
    if (this.id) {
      this.getDetail();
    }
  }
};