import "core-js/modules/es.array.push.js";
import MissionData from "./MissionData.vue";
import ClubData from "./ClubData.vue";
import PersonAssign from "./PersonAssign.vue";
export default {
  name: "SelfPerson",
  components: {
    MissionData,
    ClubData,
    PersonAssign
  },
  data() {
    return {
      menu: [{
        name: "Joined mission",
        total: 0,
        search: {
          filter: "All",
          query: "",
          origin: "join"
        },
        filterList: [{
          name: "All"
        }, {
          name: "In progress",
          divided: true
        }, {
          name: "Done"
        }, {
          name: "Free",
          divided: true
        }, {
          name: "Charge"
        }, {
          name: "Reward"
        }],
        filterTitle: "Filter",
        ref: "missionData"
      }, {
        name: "Joined clubs",
        total: 0,
        search: {
          filter: "All",
          query: "",
          origin: "join"
        },
        ref: "clubData"
      }, {
        name: "Management",
        children: [{
          total: 0,
          search: {
            filter: "All",
            query: "",
            origin: "manage"
          },
          filterList: [{
            name: "All"
          }, {
            name: "In progress",
            divided: true
          }, {
            name: "Done"
          }, {
            name: "Free",
            divided: true
          }, {
            name: "Charge"
          }, {
            name: "Reward"
          }],
          filterTitle: "Filter",
          ref: "manageMissionData"
        }, {
          total: 0,
          search: {
            filter: "All",
            query: "",
            origin: "manage"
          },
          ref: "manageClubData"
        }]
      }, {
        name: "Submission",
        total: 0,
        search: {
          filter: "All",
          query: "",
          origin: "assign"
        },
        filterList: [{
          name: "All"
        }, {
          name: "Date modified",
          divided: true
        }],
        filterTitle: "Sort",
        ref: "assignData"
      }],
      menuIndex: 0,
      manageIndex: 0,
      // 列表展示方式-card,list
      showType: "card"
    };
  },
  methods: {
    // 改变数据展示方式
    changeShowType(type) {
      this.showType = type;
      // this.getData();
    },
    // 选中filter菜单
    chooseFilter($event, item) {
      item.search.filter = $event;
      this.getData();
    },
    getData() {
      if (this.menuIndex !== 2) {
        this.$refs[`${this.menu[this.menuIndex].ref}`].getData(true);
      } else {
        this.$refs[`${this.menu[this.menuIndex].children[this.manageIndex].ref}`].getData(true);
      }
    },
    // 获取查询总数
    receiveTotal($event) {
      // 用于初始化调全部页面接口接收总数
      if ($event.name === "mission" && $event.origin === "join") {
        this.menu[0].total = $event.value;
      } else if ($event.name === "club" && $event.origin === "join") {
        this.menu[1].total = $event.value;
      } else if ($event.name === "mission" && $event.origin === "manage") {
        this.menu[2].children[0].total = $event.value;
      } else if ($event.name === "club" && $event.origin === "manage") {
        this.menu[2].children[1].total = $event.value;
      } else if ($event.name === "assign") {
        this.menu[3].total = $event.value;
      }
    },
    changeMenuIndex(index) {
      this.menuIndex = index;
      this.getData();
    },
    changeManageIndex(index) {
      this.manageIndex = index;
      this.getData();
    },
    editProfile() {
      this.$router.push({
        name: "EditProfile"
      }).catch(() => {});
    }
  },
  created() {}
};