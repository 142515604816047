import EditDraftPost from "@/components/EditDraftPost.vue";
export default {
  name: "CreatePost",
  components: {
    EditDraftPost
  },
  data() {
    return {
      // 存储选中的首页颜色index
      fileList: [],
      // 存储首页编辑数据
      editInfo: {
        title: "",
        content: "",
        radioClub: null,
        colorIndex: 0
      },
      id: "",
      origin: "",
      color: ["#fef8e7", "#8cc9de", "#47c0a9", "#cb85d6", "#ee8b34", "#53ab61"]
    };
  },
  methods: {
    back() {
      if (this.$refs.editDraftPost?.judgeEditinfo()) {
        this.$tipModal({
          visible: true,
          tipObj: {
            title: "Whoa,hold up!",
            content: "Leaving the page will lose data, choose to save the data first",
            footer: [{
              name: "Back",
              type: undefined,
              query: "back",
              class: "default-button",
              fn: this.$router.back
            }, {
              name: "Save",
              type: "primary",
              query: this.origin === "post" ? "savePost" : "saveDraft",
              class: "primary-button",
              fn: this.$refs.editDraftPost.receive
            }]
          }
        });
      } else {
        this.$router.back();
      }
    },
    getDetail() {
      return new Promise((resolve, reject) => {
        const params = {
          timeZone: this.$timezone,
          [`${this.origin}Id`]: this.id
        };
        this.$http({
          url: `/api/${this.origin}/getDetail`,
          //请求的后台接口
          method: "get",
          //get请求方式
          params
        }).then(({
          data: res
        }) => {
          if (res.success) {
            const {
              title,
              content,
              imageUrls,
              backgroundColor,
              clubId
            } = res.data;
            this.editInfo.title = title;
            this.editInfo.content = content;
            this.fileList = imageUrls.map(e => {
              return {
                name: "post.png",
                url: e,
                // 鼠标悬浮图片上面出现删除按钮
                imageDelete: false
              };
            });
            const index = this.color.findIndex(e => e === backgroundColor);
            this.editInfo.colorIndex = index === -1 ? 0 : index;
            const elm = this.$refs.editDraftPost.$refs.middle.querySelector("#middle-color");
            elm.setAttribute("style", "fill:" + this.color[this.editInfo.colorIndex]);
            resolve(clubId);
          }
        }).catch(() => {});
      });
    },
    getClubDetail(clubId) {
      return new Promise(resolve => {
        const params = {
          timeZone: this.$timezone,
          clubId: clubId
        };
        this.$http({
          url: "/api/club/detail",
          //请求的后台接口
          method: "get",
          //get请求方式
          params
        }).then(({
          data: res
        }) => {
          if (res.success) {
            resolve(res.data);
          }
        }).catch(() => {});
      });
    }
  },
  async created() {
    this.id = this.$route.query?.id || "";
    this.origin = this.$route.query?.origin || "post";
    let clubId;
    if (this.id) {
      clubId = await this.getDetail();
    }
    if (this.$route.query?.clubId || clubId) {
      this.getClubDetail(this.$route.query?.clubId || clubId).then(resp => {
        if (resp.followState) {
          this.editInfo.radioClub = resp;
        }
      });
    }
  }
};