import { useMyStore } from "@/assets/store";
import { mapState, mapActions } from "pinia";
export default {
  computed: {
    ...mapState(useMyStore, ["isMobile", "info","messageCount"]),
  },
  methods: {
    ...mapActions(useMyStore, ["judgeIsMobile", "getInfoJudge"]),
    fmtLink(value) {
      const exp =
        /(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/g;
      return value
        ? value.replace(
            exp,
            '<a href="$&" target="_blank" style="text-decoration:none;color: #fc4f00;" class="a">$&</a>'
          )
        : "";
    },
    preventDefault(e) {
      if (e.target.className === "a") {
        e.stopPropagation();
      }
    },
    toHome() {
      this.$router
        .push({
          name: "SelfHome",
        })
        .catch(() => {});
    },
    clickLike(item, type) {
      item.likedByMe = !item.likedByMe;
      if (item.setTimeout) {
        clearTimeout(item.setTimeout);
        item.setTimeout = null;
      }
      if (!item.likedByMe && item.likedByMeOrigin) {
        item.likeCount--;
        item.likedByMeOrigin = !item.likedByMeOrigin;
        item.setTimeout = window.setTimeout(() => {
          this.unlike(item, type);
        }, 2000);
      } else if (item.likedByMe && !item.likedByMeOrigin) {
        item.likeCount++;
        item.likedByMeOrigin = !item.likedByMeOrigin;
        item.setTimeout = window.setTimeout(() => {
          this.like(item, type);
        }, 2000);
      }
    },
    like(item, type) {
      this.$http({
        method: "post",
        url: `/api/${type}/like?${type}Id=${item.id}`,
        config: { disabledLoading: true },
      })
        .then(() => {})
        .catch(() => {});
    },
    unlike(item, type) {
      this.$http({
        method: "post",
        url: `/api/${type}/unLike?${type}Id=${item.id}`,
        config: { disabledLoading: true },
      })
        .then(() => {})
        .catch(() => {});
    },
    postShared(item, type) {
      this.$http({
        method: "post",
        url: `/api/${item.origin}/share?${item.origin}Id=${item.id}&shareType=${type}`,
        config: { disabledLoading: true },
      })
        .then(() => {})
        .catch(() => {});
    },
    isLogin($event) {
      if (!$event || $event.target.className !== "a") {
        const login = this.$particle.auth.isLogin();
        if (!login) {
          this.$loginModal({
            visible: true,
            next: null,
          });
          // if ($event) {
          //     $event.stopPropagation();
          // }
          return true;
        }
      }
      return false;
    },
  },
};
