import "core-js/modules/es.array.push.js";
export default {
  name: "MissionComplete",
  data() {
    return {
      id: "",
      showPay: false,
      list: [],
      completeNum: 0,
      completeData: null,
      selectList: []
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 返回
    back() {
      if (!this.showPay) {
        this.$router.back();
      } else {
        this.showPay = false;
      }
    },
    complete() {
      this.selectList = this.$refs.tableMission.getSelectionRows();
      if (!this.selectList.length) {
        this.$tipModal({
          visible: true,
          tipObj: {
            title: "",
            content: "Please select the person who needs to settle",
            footer: [{
              name: "Close",
              type: undefined,
              query: "close",
              class: "default-button"
            }]
          }
        });
        return;
      }
      const params = {
        missionId: this.id,
        rewardUserIdList: this.selectList.map(e => e.userId)
      };
      this.$http({
        url: "/api/mission/settleSummary",
        //请求的后台接口
        method: "post",
        //get请求方式
        data: params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.completeData = res.data;
          this.showPay = true;
        }
      }).catch(() => {});
    },
    confirm() {
      const params = {
        missionId: this.id,
        rewardUserIdList: this.selectList.map(e => e.userId)
      };
      this.$http({
        url: "/api/mission/doSettle",
        //请求的后台接口
        method: "post",
        //get请求方式
        data: params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.$router.push({
            name: "MissionPaySuccess",
            query: {
              missionId: this.id,
              origin: "completed"
            }
          });
        }
      }).catch(() => {});
    },
    dianzan(item, type) {
      if (this.isLogin()) {
        return;
      }
      this.clickLike(item, type);
    },
    getAllData() {
      return new Promise(resolve => {
        const params = {
          missionId: this.id,
          page: 1,
          size: 9999
        };
        this.$http({
          url: "/api/mission/listGradeRank",
          //请求的后台接口
          method: "get",
          //get请求方式
          params
        }).then(({
          data: res
        }) => {
          if (res.success) {
            this.list = res.data.list.map(e => {
              return {
                ...e,
                likedByMeOrigin: e.likedByMe
              };
            });
            this.completeNum = this.list.filter(e => e.tagClose !== "0").length;
            this.$nextTick(function () {
              this.list.forEach(row => {
                this.$refs.tableMission.toggleRowSelection(row);
              });
            });
            resolve(this.list);
          }
        }).catch(() => {});
      });
    }
  },
  created() {
    this.id = this.$route.query?.missionId || "";
    this.getAllData();
  }
};