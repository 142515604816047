import "core-js/modules/es.array.push.js";
import SharedModal from "./SharedModal";
import TipModal from "@/components/TipModal.vue";
import ClubCard from "@/components/ClubCard.vue";
export default {
  name: "ClubData",
  props: ["showType", "searchInfo", "canLoad"],
  components: {
    SharedModal,
    TipModal,
    ClubCard
  },
  data() {
    return {
      // 存储club列表
      club: [],
      clubTotal: 0,
      // 加载中
      loading: true,
      loadingTable: false,
      clubIndex: 1,
      otherInfo: null,
      reportUrl: "",
      showReport: false,
      delData: null
    };
  },
  computed: {
    // 无法展示更多
    noMore() {
      return this.club.length === this.clubTotal;
    },
    // 加载中防抖节流
    disabled() {
      return this.loading;
    }
  },
  methods: {
    indexMethod(index) {
      const key = index + 1;
      return key >= 10 ? key : "0" + key;
    },
    loadTable() {
      if (this.clubIndex * 10 < this.clubTotal && this.canLoad) {
        this.loadingTable = true;
        this.clubIndex++;
        this.getData(false);
      }
    },
    load() {
      if (this.clubIndex * 10 < this.clubTotal && this.canLoad) {
        this.loading = true;
        this.clubIndex++;
        this.getData(false);
      }
    },
    async getData(reset) {
      if (reset) {
        this.club = [];
        this.clubIndex = 1;
      }
      const data = await this.getAllData();
      this.club = [...this.club, ...data];
      if (this.showType === "card") {
        this.loading = false;
      } else {
        this.loadingTable = false;
      }
      return true;
    },
    // filter为all
    getAllData() {
      return new Promise(resolve => {
        const {
          filter,
          query,
          origin
        } = this.searchInfo;
        const params = {
          timeZone: this.$timezone,
          name: query.trim(),
          page: this.clubIndex,
          size: 10
        };
        if (origin === "join" || origin === "otherClub") {
          params.userId = this.otherInfo.id;
        } else if (origin === "manage") {
          params.creatorId = this.otherInfo.id;
        }
        let url = "";
        if (origin === "all") {
          url = filter !== "Popular" ? "api/club/list" : "api/club/listPopular";
        } else if (origin === "join" || origin === "otherClub") {
          url = "api/club/listFollowed";
        } else if (origin === "manage") {
          url = "api/club/list";
        }
        if (url === "api/club/list") {
          params.tagDelete = "0";
        }
        this.$http({
          url: "/" + url,
          //请求的后台接口
          method: "get",
          //get请求方式
          params
        }).then(({
          data: res
        }) => {
          if (res.success) {
            this.clubTotal = res.data.totalCount;
            this.$emit(this.searchInfo.origin !== "manage" ? "clubTotal" : "manageClubTotal", {
              name: "club",
              value: res.data.totalCount,
              origin: this.searchInfo.origin
            });
            resolve(res.data.list || []);
          }
        }).catch(() => {});
      });
    },
    cancelDel() {
      this.delData = null;
    },
    // 编辑删除分享
    chooseMenu($event, item) {
      if ($event === "delete") {
        // this.deleteData(item);
        this.delData = JSON.parse(JSON.stringify(item));
        this.$tipModal({
          visible: true,
          tipObj: {
            title: "",
            content: "Are you sure you want to delete this?",
            footer: [{
              name: "Cancel",
              type: undefined,
              query: "cancel",
              class: "default-button",
              fn: this.cancelDel
            }, {
              name: "Confirm",
              type: "primary",
              query: "confirmDelete",
              class: "primary-button",
              fn: () => this.deleteData(this.delData)
            }]
          }
        });
      } else if ($event === "edit") {
        this.edit(item);
      } else {
        this.shared(item);
      }
    },
    // 删除
    deleteData(item) {
      this.$http({
        url: "/api/club/delete?clubId=" + item.id,
        //请求的后台接口
        method: "post" //get请求方式
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.getData(true);
          this.delData = null;
        }
      }).catch(() => {});
    },
    // 编辑
    edit(item) {
      this.$router.push({
        name: "CreateClub",
        query: {
          id: item.id
        }
      }).catch(() => {});
    },
    shared(item) {
      this.showReport = true;
      this.reportUrl = {
        url: location.origin + "/#/club-detail?id=" + item.id + "&shared=true",
        data: {
          id: item.id,
          origin: "club"
        }
      };
    },
    report() {
      this.showReport = false;
    },
    clubDetail(item) {
      this.$router.push({
        name: "ClubDetail",
        query: {
          id: item.id
        }
      }).catch(() => {});
    }
  },
  created() {
    const userid = sessionStorage.getItem("otherInfo");
    if (userid) {
      this.otherInfo = {
        id: userid
      };
    } else {
      this.otherInfo = JSON.parse(JSON.stringify(this.info));
    }
    this.getData(false);
  }
};