import "core-js/modules/es.array.push.js";
import Masonry from "masonry-layout";
import CommentAll from "@/components/CommentAll.vue";
import SharedModal from "./SharedModal";
export default {
  name: "PersonAssign",
  // searchInfo的origin有"assign" ，"otherAssignments"，"draft"
  props: ["showType", "searchInfo", "canLoad", "missionDetail"],
  components: {
    CommentAll,
    SharedModal
  },
  data() {
    return {
      // 存储mission列表
      mission: [],
      missionTotal: 0,
      completeNum: 0,
      // 加载中
      loading: true,
      loadingTable: false,
      missionIndex: 1,
      otherInfo: null,
      showComment: false,
      commentObj: null,
      reportUrl: null,
      showReport: false,
      delData: null,
      width: 0
    };
  },
  computed: {
    // 无法展示更多
    noMore() {
      return this.mission.length === this.missionTotal;
    },
    // 加载中防抖节流
    disabled() {
      return this.loading;
    }
  },
  methods: {
    showCommentModal(item) {
      this.showComment = true;
      this.commentObj = {
        id: item.id,
        origin: "assignment"
      };
    },
    tableRowClassName({
      row
    }) {
      if (row.gradeRate) {
        return "success-row";
      }
      return "";
    },
    indexMethod(index) {
      const key = index + 1;
      return key >= 10 ? key : "0" + key;
    },
    loadTable() {
      if (this.missionIndex * 10 < this.missionTotal && this.canLoad) {
        this.loadingTable = true;
        this.missionIndex++;
        this.getData(false);
      }
    },
    load() {
      if (this.missionIndex * 10 < this.missionTotal && this.canLoad) {
        this.loading = true;
        this.missionIndex++;
        this.getData(false);
      }
    },
    async getData(reset) {
      if (reset) {
        this.mission = [];
        this.missionIndex = 1;
      }
      const data = await this.getAllData();
      this.mission = [...this.mission, ...data];
      this.completeNum = this.mission.filter(e => e.tagClose !== "0").length;
      if (this.showType === "card") {
        this.loading = false;
      } else {
        this.loadingTable = false;
      }
      return true;
    },
    // filter为all
    getAllData() {
      return new Promise(resolve => {
        const {
          filter,
          query,
          origin
        } = this.searchInfo;
        const params = {
          timeZone: this.$timezone,
          title: query.trim(),
          page: this.missionIndex,
          size: 10,
          tagDelete: "0"
        };
        if (origin === "assign" || origin === "otherAssignments") {
          params.orders = filter === "All" ? "all" : "mod";
        }
        if (origin !== "missionAssign") {
          params.creatorId = this.otherInfo.id;
        } else {
          params.missionId = this.missionDetail.id;
        }
        let url = "";
        if (origin === "draft") {
          url = "api/draft/list";
        } else {
          url = "api/assignment/list";
        }
        this.$http({
          url: "/" + url,
          //请求的后台接口
          method: "get",
          //get请求方式
          params
        }).then(({
          data: res
        }) => {
          if (res.success) {
            this.missionTotal = res.data.totalCount;
            this.$emit("assignTotal", {
              name: "assign",
              value: res.data.totalCount,
              origin: this.searchInfo.origin
            });
            const data = res.data.list.map(e => {
              return {
                ...e,
                setTimeout: null,
                likedByMeOrigin: e.likedByMe,
                rate: e.gradeRate
              };
            });
            resolve(data);
          }
        }).catch(() => {});
      });
    },
    // my drafts鼠标悬浮展示按钮
    showButton(item) {
      if (this.searchInfo.origin === "draft") {
        item.showButton = true;
      }
    },
    cancelDel() {
      this.delData = null;
    },
    // 编辑删除分享
    chooseMenu($event, item) {
      if ($event === "jvbao") {
        this.jvbao();
      } else if ($event === "grade") {
        item.rateVisble = true;
      } else if ($event === "delete") {
        // this.deleteData(item);
        this.delData = JSON.parse(JSON.stringify(item));
        this.$tipModal({
          visible: true,
          tipObj: {
            title: "",
            content: "Are you sure you want to delete this?",
            footer: [{
              name: "Cancel",
              type: undefined,
              query: "cancel",
              class: "default-button",
              fn: this.cancelDel
            }, {
              name: "Confirm",
              type: "primary",
              query: "confirmDelete",
              class: "primary-button",
              fn: () => this.deleteData(this.delData)
            }]
          }
        });
      } else if ($event === "edit") {
        this.edit(item);
      } else {
        this.shared(item);
      }
    },
    jvbao() {
      this.$tipModal({
        visible: true,
        tipObj: {
          title: "",
          content: "Report successful",
          footer: [{
            name: "Close",
            type: undefined,
            query: "close",
            class: "default-button"
          }]
        }
      });
    },
    // 删除
    deleteData(item) {
      const params = this.searchInfo.origin === "assign" ? "?assignmentId=" + item.id : "?draftId=" + item.id;
      const url = this.searchInfo.origin === "assign" ? "api/assignment/delete" : "api/draft/delete";
      this.$http.post("/" + url + params).then(({
        data: res
      }) => {
        if (res.success) {
          this.delData = null;
          this.getData(true);
        }
      }).catch(() => {});
    },
    // 编辑
    edit(item) {
      this.$router.push({
        name: this.searchInfo.origin === "assign" ? "CreateAssignment" : "CreatePost",
        query: {
          id: item.id,
          origin: this.searchInfo.origin === "assign" ? undefined : "draft"
        }
      }).catch(() => {});
    },
    shared(item) {
      this.showReport = true;
      this.reportUrl = {
        url: location.origin + "/#/?share=" + JSON.stringify({
          id: item.id,
          origin: "assignment"
        }) + "&shared=true",
        data: {
          id: item.id,
          origin: "assignment"
        }
      };
    },
    report($event) {
      if ($event !== "cancel") {
        this.postShared(this.reportUrl.data, $event);
      }
      this.showReport = false;
    },
    toOther(item) {
      if (item.userId !== this.otherInfo.id) {
        this.$router.push({
          name: "FollowOther",
          query: {
            userId: item.userId
          }
        });
      } else {
        this.$router.push({
          name: "PersonalManage",
          query: {
            index: "3"
          }
        });
      }
    },
    dianzan(item, type) {
      if (this.isLogin()) {
        return;
      }
      this.clickLike(item, type);
    },
    rate(item) {
      if (item.rate) {
        this.$http.post(`/api/assignment/putScoreGradeRate?assignmentId=${item.id}&gradeRate=${item.rate}`).then(({
          data: res
        }) => {
          if (res.success) {
            item.rateVisble = false;
            item.gradeRate = item.rate;
            this.$emit("rate");
          }
        }).catch(() => {});
      }
    },
    updateMasonry() {
      this.masonry = new Masonry(document.querySelector(".assign-row"), {
        itemSelector: ".assign-col",
        columnWidth: ".assign-col"
      });
      this.masonry.layout();
    }
  },
  created() {
    const userid = sessionStorage.getItem("otherInfo");
    if (userid) {
      this.otherInfo = {
        id: userid
      };
    } else {
      this.otherInfo = JSON.parse(JSON.stringify(this.info));
    }
    this.getData(true);
  },
  updated() {
    if (this.showType === "card") {
      this.updateMasonry();
    }
    if (!this.width) {
      this.$nextTick(() => {
        const width = document.getElementById("person-assign").offsetWidth;
        this.width = width;
      });
    }
  }
};