export default {
  name: "SharedModal",
  props: ["url"],
  methods: {
    report(type) {
      if (type !== "cancel") {
        this.$copyText(this.url).then(() => {
          this.$tipModal({
            visible: true,
            tipObj: {
              title: "",
              content: "The copy succeeded!",
              footer: [{
                name: "Close",
                type: undefined,
                query: "close",
                class: "default-button"
              }]
            }
          });
          this.$emit("report", type);
        }, () => {
          this.$tipModal({
            visible: true,
            tipObj: {
              title: "",
              content: "The copy failed!",
              footer: [{
                name: "Close",
                type: undefined,
                query: "close",
                class: "default-button"
              }]
            }
          });
          // this.$message.error("The copy failed!");
        });
      } else {
        this.$emit("report", type);
      }
    }
  }
};