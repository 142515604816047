import CommentAllAssign from "@/components/CommentAllAssign.vue";
export default {
  name: "MissionRank",
  components: {
    CommentAllAssign
  },
  data() {
    return {
      id: "",
      list: [],
      missionTotal: 0,
      missionIndex: 1,
      showComment: false,
      commentObj: null
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 返回
    back() {
      this.$router.back();
    },
    showCommentModal(item) {
      this.showComment = true;
      this.commentObj = {
        creatorId: item.userId,
        missionId: this.id,
        origin: "assignment"
      };
    },
    dianzan(item, type) {
      if (this.isLogin()) {
        return;
      }
      this.clickLike(item, type);
    },
    getAllData() {
      return new Promise(resolve => {
        const params = {
          page: this.missionIndex,
          size: 10,
          missionId: this.id
        };
        let url = "/api/mission/listGradeRank";
        this.$http({
          url,
          //请求的后台接口
          method: "get",
          //get请求方式
          params
        }).then(({
          data: res
        }) => {
          if (res.success) {
            this.missionTotal = res.data.totalCount;
            this.list = res.data.list.map((e, index) => {
              return {
                ...e,
                setTimeout: null,
                likedByMeOrigin: e.likedByMe,
                rate: e.gradeRate,
                index
              };
            });
            resolve(this.list);
          }
        }).catch(() => {});
      });
    }
  },
  created() {
    this.id = this.$route.query?.id || "";
    this.getAllData();
  }
};