import moment from "moment-timezone";

// 获取时区名称数组
const timeZoneNames = moment.tz.names();

// 格式化为带有 GMT 偏移值的格式
const formattedTimeZones = timeZoneNames.map((zoneName) => {
    // 使用 tz() 方法获取对应的 Moment.js 时区实例
    const timeZone = moment.tz(zoneName);

    // 使用 utcOffset() 方法获取当前时间相对于 GMT 的分钟偏移值
    const offsetMinutes = timeZone.utcOffset();

    // 将分钟偏移值转换为小时和分钟的形式
    const offsetHours = Math.floor(offsetMinutes / 60);
    const offsetMinutesString = Math.abs(offsetMinutes % 60)
        .toString()
        .padStart(2, "0");

    // 构建 GMT 偏移字符串
    const gmtOffset = `GMT${offsetHours >= 0 ? "+" : "-"}${Math.abs(offsetHours)
    .toString()
    .padStart(2, "0")}${offsetMinutesString}`;

    const partitionZone = zoneName.split('/');
    // 返回格式化后的字符串，带有时区名称和 GMT 偏移值
    return {
        value: zoneName,
        label: `(${gmtOffset})${partitionZone[0]}(${partitionZone[1]}) Time`,
        gmtOffset,
        zone:`${partitionZone[0]}(${partitionZone[1]})`
    };
});
export default formattedTimeZones;