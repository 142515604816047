export default {
  name: "MissionPaySuccess",
  data() {
    return {
      payId: "",
      id: "",
      origin: "",
      // completed或joined
      type: ""
    };
  },
  computed: {},
  watch: {},
  methods: {
    getPayDetail() {
      const params = {
        billId: this.payId,
        timeZone: this.$timezone
      };
      this.$http({
        url: "/api/account/getTransactionDetail",
        //请求的后台接口
        method: "get",
        //get请求方式
        params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.id = res.data.bisId;
          this.type = res.data.tagSuccess === "0" ? "success" : "error";
        }
      }).catch(() => {});
    },
    // 返回
    back() {
      this.$router.back();
    }
  },
  created() {
    this.origin = this.$route.query?.origin || "";
    if (this.origin === "joined") {
      this.payId = this.$route.query?.payId || "";
      this.getPayDetail();
    } else if (this.origin === "completed") {
      this.id = this.$route.query?.missionId || "";
      this.type = "success";
    }
  }
};