export default {
  name: "MissionNumModal",
  props: ["selectMoney", "selectRemark", "select", "selectDivision"],
  data() {
    return {
      num: 0,
      remark: "",
      division: "",
      radio: [{
        name: "100%",
        value: 1,
        height: "100%"
      }, {
        name: "70%",
        value: 0.7,
        height: "70%"
      }, {
        name: "50%",
        value: 0.5,
        height: "50%"
      }, {
        name: "100%",
        value: 0,
        height: "0%"
      }, {
        name: "30%",
        value: 0.3,
        height: "30%"
      }]
    };
  },
  methods: {
    missionModal(type) {
      this.$emit("missionModal", type === "cancel" ? null : {
        num: this.num,
        remark: this.remark,
        division: this.division
      });
    }
  },
  created() {
    this.num = this.selectMoney;
    if (this.select === "Reward") {
      this.remark = this.selectRemark;
      this.division = this.selectDivision;
    }
  }
};