import "core-js/modules/es.array.push.js";
import SharedModal from "@/components/SharedModal";
export default {
  name: "ClubCard",
  props: ["item", "searchInfo"],
  components: {
    SharedModal
  },
  data() {
    return {
      reportUrl: null,
      showReport: false
    };
  },
  methods: {
    // 查看club详情
    clubDetail(item) {
      this.$router.push({
        name: "ClubDetail",
        query: {
          id: item.id
        }
      }).catch(() => {});
    },
    cancelDel() {
      this.delData = null;
    },
    // 编辑删除分享
    chooseMenu($event, item) {
      if ($event === "delete") {
        // this.deleteData(item);
        this.delData = JSON.parse(JSON.stringify(item));
        this.$tipModal({
          visible: true,
          tipObj: {
            title: "",
            content: "Are you sure you want to delete this?",
            footer: [{
              name: "Cancel",
              type: undefined,
              query: "cancel",
              class: "default-button",
              fn: this.cancelDel
            }, {
              name: "Confirm",
              type: "primary",
              query: "confirmDelete",
              class: "primary-button",
              fn: () => this.deleteData(this.delData)
            }]
          }
        });
      } else if ($event === "edit") {
        this.edit(item);
      } else {
        this.shared(item);
      }
    },
    // 删除
    deleteData(item) {
      this.$http({
        url: "/api/club/delete?clubId=" + item.id,
        //请求的后台接口
        method: "post" //get请求方式
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.$emit("getClubData");
          this.delData = null;
        }
      }).catch(() => {});
    },
    // 编辑
    edit(item) {
      this.$router.push({
        name: "CreateClub",
        query: {
          id: item.id
        }
      }).catch(() => {});
    },
    shared(item) {
      this.showReport = true;
      this.reportUrl = {
        url: location.origin + "/#/club-detail?id=" + item.id + "&shared=true",
        data: {
          id: item.id,
          origin: "club"
        }
      };
    },
    report() {
      this.showReport = false;
    }
  }
};