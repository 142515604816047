import "core-js/modules/es.array.push.js";
export default {
  name: "CreateClub",
  data() {
    return {
      index: 0,
      name: "",
      introduction: "",
      followerName: "",
      clubOrigin: "",
      category: "",
      categoryList: [],
      fileList: [],
      linkUrl: "",
      agree: false,
      id: ""
    };
  },
  computed: {},
  watch: {},
  methods: {
    getCategoryList() {
      this.$http.get("/api/club/listCategory").then(({
        data: res
      }) => {
        if (res.success) {
          this.categoryList = res.data;
        }
      }).catch(() => {});
    },
    // 删除图片
    handleRemove(file) {
      const index = this.fileList.findIndex(object => {
        return object.url === file.url;
      });
      this.fileList.splice(index, 1);
    },
    // 上传文件成功后将其添加至fileList
    upload(file) {
      // this.fileList.push(file);
      const formData = new FormData();
      formData.append("file", file.file);
      this.$refs.uploadFile.clearFiles();
      this.$http.post("/api/file/fileUpload", formData).then(({
        data: res
      }) => {
        if (res.success) {
          this.fileList.push({
            name: res.data.fileName,
            url: res.data.url,
            // 鼠标悬浮图片上面出现删除按钮
            imageDelete: false
          });
        }
      }).catch(() => {});
    },
    beforeUpload(file) {
      if (file.type.indexOf("image") > -1) {
        return true;
      } else {
        return false;
      }
    },
    // 返回
    back() {
      this.$router.back();
      // this.$router.push({
      //   name: "PersonalManage",
      //   query: {
      //     index: "2-2",
      //   },
      // });
    },
    tip(content) {
      this.$tipModal({
        visible: true,
        tipObj: {
          title: "",
          content,
          footer: [{
            name: "Close",
            type: undefined,
            query: "close",
            class: "default-button"
          }]
        }
      });
    },
    save() {
      if (this.index === 0) {
        if (!this.name.trim()) {
          this.tip("Please maintain the name");
          return;
        }
        if (!this.introduction.trim()) {
          this.tip("Please maintain the introduction");
          return;
        }
        if (!this.clubOrigin.trim()) {
          this.tip("Please maintain the clubOrigin");
          return;
        }
        this.index = 1;
      } else if (this.index === 1) {
        if (!this.fileList.length && !this.linkUrl) {
          this.tip("Please maintain the cover image");
          return;
        }
        this.index = 2;
      } else {
        if (!this.agree) {
          this.tip("Please agree before you can create it");
          return;
        }
        const params = {
          id: this.id || undefined,
          name: this.name.trim(),
          introduction: this.introduction.trim(),
          followerName: this.followerName.trim(),
          clubOrigin: this.clubOrigin.trim(),
          category: this.category,
          imageUrl: this.linkUrl || this.fileList[0].url
        };
        const url = this.id ? "api/club/updateOne" : "api/club/create";
        this.$http.post("/" + url, params).then(({
          data: res
        }) => {
          if (res.success) {
            this.back();
          }
        }).catch(() => {});
      }
    },
    getDetail() {
      const params = {
        timeZone: this.$timezone,
        clubId: this.id
      };
      this.$http({
        url: "/api/club/detail",
        //请求的后台接口
        method: "get",
        //get请求方式
        params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          const {
            name,
            introduction,
            followerName,
            imageUrl,
            clubOrigin,
            category
          } = res.data;
          this.name = name;
          this.introduction = introduction;
          this.followerName = followerName;
          this.fileList = [{
            name: "club.png",
            url: imageUrl,
            // 鼠标悬浮图片上面出现删除按钮
            imageDelete: false
          }];
          this.clubOrigin = clubOrigin;
          this.category = category;
          this.agree = true;
        }
      }).catch(() => {});
    }
  },
  created() {
    this.getCategoryList();
    this.id = this.$route.query?.id || "";
    if (this.id) {
      this.getDetail();
    }
  }
};