import "core-js/modules/es.array.push.js";
import SharedModal from "@/components/SharedModal";
import MissionQuitModal from "@/components/MissionQuitModal";
export default {
  name: "MissionCard",
  props: ["item", "searchInfo"],
  components: {
    SharedModal,
    MissionQuitModal
  },
  data() {
    return {
      reportUrl: null,
      showReport: false,
      showMissionQuit: false,
      quitItem: null
    };
  },
  methods: {
    // 查看mission详情
    missionDetail(item) {
      this.$router.push({
        name: "MissionDetail",
        query: {
          id: item.id
        }
      }).catch(() => {});
    },
    shared(item, type) {
      this.showReport = true;
      this.reportUrl = {
        url: location.origin + "/#/mission-detail?id=" + item.id + "&shared=true",
        data: {
          id: item.id,
          origin: type
        }
      };
    },
    report($event) {
      if ($event !== "cancel") {
        this.postShared(this.reportUrl.data, $event);
      }
      this.showReport = false;
    },
    cancelDel() {
      this.delData = null;
    },
    // 编辑删除分享
    chooseMenu($event, item) {
      if ($event === "delete") {
        // this.deleteData(item);
        this.delData = JSON.parse(JSON.stringify(item));
        this.$tipModal({
          visible: true,
          tipObj: {
            title: "",
            content: "Are you sure you want to delete this?",
            footer: [{
              name: "Cancel",
              type: undefined,
              query: "cancel",
              class: "default-button",
              fn: this.cancelDel
            }, {
              name: "Confirm",
              type: "primary",
              query: "confirmDelete",
              class: "primary-button",
              fn: () => this.deleteData(this.delData)
            }]
          }
        });
      } else if ($event === "share") {
        this.shared(item, "mission");
      } else if ($event === "jvbao") {
        this.jvbao();
      } else if ($event === "quit") {
        this.showMissionQuit = true;
        this.quitItem = item;
      } else if ($event === "cancel") {
        this.cancelMission(item);
      }
    },
    // 取消mission
    cancelMission(item) {
      this.$http.post(`/api/mission/cancel?missionId=${item.id}`).then(({
        data: res
      }) => {
        if (res.success) {
          item.progressStatus = "5";
        }
      }).catch(() => {});
    },
    // 删除
    deleteData(item) {
      this.$http({
        url: "/api/mission/delete?missionId=" + item.id,
        //请求的后台接口
        method: "post" //get请求方式
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.$emit("getMissionData");
          this.delData = null;
        }
      }).catch(() => {});
    },
    jvbao() {
      this.$tipModal({
        visible: true,
        tipObj: {
          title: "",
          content: "Report successful",
          footer: [{
            name: "Close",
            type: undefined,
            query: "close",
            class: "default-button"
          }]
        }
      });
    },
    // 退出
    missionQuit($event) {
      if ($event === "Quit") {
        this.join();
      }
      this.showMissionQuit = false;
    },
    join() {
      if (this.isLogin(null)) {
        return;
      }
      this.$http.post(`/api/mission/${!this.quitItem.joinState ? "join" : "leave"}?missionId=` + this.quitItem.id).then(({
        data: res
      }) => {
        if (res.success) {
          this.$emit("getMissionData");
          this.quitItem = null;
        }
      }).catch(() => {});
    }
  }
};