import "core-js/modules/es.array.push.js";
export default {
  name: "UserIcon",
  data() {
    return {
      messageContent: []
    };
  },
  methods: {
    login() {
      this.$loginModal({
        visible: true,
        next: null
      });
    },
    goToWallet() {
      this.$router.push({
        name: "WalletTransfers"
      });
    },
    goBack() {
      if (location.hash !== "#/") {
        this.toHome();
      }
    },
    logout() {
      this.$particle.auth.logout().then(() => {
        const user = JSON.parse(sessionStorage.getItem("user"));
        const params = {
          uuid: user.uuid,
          token: user.token
        };
        this.$http.post("/api/auth/logoutSpecial", params).then(({
          data: res
        }) => {
          if (res.success) {
            if (this.$route.name === "SelfHome") {
              location.reload();
            } else {
              this.$router.push({
                name: "SelfHome"
              });
            }
            sessionStorage.clear();
          }
        }).catch(() => {});
      });
    },
    toOther() {
      if (this.isLogin()) {
        return;
      }
      if (this.$route.query.index !== "3") {
        this.$router.push({
          name: "PersonalManage",
          query: {
            index: "3"
          }
        }).catch(() => {});
      }
    },
    showMessage() {
      const params = {
        page: 1,
        size: 9999,
        tagRead: "",
        timeZone: this.$timezone,
        userId: this.info.id
      };
      this.$http({
        url: "/api/notification/list",
        //请求的后台接口
        method: "get",
        //get请求方式
        params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.messageContent = res.data.list;
        }
      }).catch(() => {});
    }
  },
  created() {}
};