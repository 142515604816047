import CommentSingleAssign from "@/components/CommentSingleAssign.vue";
export default {
  name: "CommentAllAssign",
  props: ["obj"],
  components: {
    CommentSingleAssign
  },
  data() {
    return {
      list: []
    };
  },
  computed: {},
  methods: {
    back() {
      this.$emit("commentBack");
    },
    getDetailData() {
      const params = {
        creatorId: this.obj.creatorId,
        missionId: this.obj.missionId,
        timeZone: this.$timezone,
        tagDelete: '0',
        orders: "all",
        page: 1,
        size: 99999
      };
      this.$http({
        url: `/api/assignment/list`,
        //请求的后台接口
        method: "get",
        //get请求方式
        params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.list = res.data.list;
        }
      }).catch(() => {});
    }
  },
  created() {
    this.getDetailData();
  }
};