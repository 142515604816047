export default {
  name: "TipModal",
  props: ["visible", "tipObj", "close"],
  methods: {
    async hidden(item) {
      if (item?.fn) {
        await item.fn(item.query);
      }
      this.close();
    }
  }
};