import "core-js/modules/es.array.push.js";
import MissionCard from "@/components/MissionCard.vue";
import SharedModal from "./SharedModal";
export default {
  name: "MisiionData",
  props: ["showType", "searchInfo", "canLoad"],
  components: {
    SharedModal,
    MissionCard
  },
  data() {
    return {
      // 存储mission列表
      mission: [],
      missionTotal: 0,
      // 加载中
      loading: true,
      loadingTable: false,
      missionIndex: 1,
      otherInfo: null,
      reportUrl: null,
      showReport: false,
      delData: null
    };
  },
  computed: {
    // 无法展示更多
    noMore() {
      return this.mission.length === this.missionTotal;
    },
    // 加载中防抖节流
    disabled() {
      return this.loading;
    }
  },
  methods: {
    tableRowClassName({
      row
    }) {
      if (row.tagClose === "1") {
        return "success-row";
      }
      return "";
    },
    indexMethod(index) {
      const key = index + 1;
      return key >= 10 ? key : "0" + key;
    },
    loadTable() {
      if (this.missionIndex * 10 < this.missionTotal && this.canLoad) {
        this.loadingTable = true;
        this.missionIndex++;
        this.getData(false);
      }
    },
    load() {
      if (this.missionIndex * 10 < this.missionTotal && this.canLoad) {
        this.loading = true;
        this.missionIndex++;
        this.getData(false);
      }
    },
    async getData(reset) {
      if (reset) {
        this.mission = [];
        this.missionIndex = 1;
      }
      const data = await this.getAllData();
      this.mission = [...this.mission, ...data];
      if (this.showType === "card") {
        this.loading = false;
      } else {
        this.loadingTable = false;
      }
      return true;
    },
    // filter为all
    getAllData() {
      return new Promise(resolve => {
        const {
          filter,
          query,
          origin
        } = this.searchInfo;
        const params = {
          timeZone: this.$timezone,
          title: query.trim(),
          page: this.missionIndex,
          size: 10
        };
        if (filter === "Done") {
          params.tagClose = "1";
        }
        if (filter === "In progress") {
          params.tagClose = "0";
        }
        if (filter === "Past") {
          params.tagClose = "1";
        }
        if (filter === "Free") {
          params.filter = "0";
        }
        if (filter === "Charge") {
          params.filter = "1";
        }
        if (filter === "Reward") {
          params.filter = "2";
        }
        if (origin === "join" || origin === "otherMission") {
          params.userId = this.otherInfo.id;
        } else if (origin === "manage") {
          params.creatorId = this.otherInfo.id;
        }
        let url = "";
        if (origin === "all") {
          url = filter !== "Popular" ? "api/mission/list" : "api/mission/listPopular";
        } else if (origin === "join" || origin === "otherMission") {
          url = "api/mission/listJoined";
        } else if (origin === "manage") {
          url = "api/mission/list";
        }
        if (url === "api/mission/list") {
          params.tagDelete = "0";
        }
        this.$http({
          url: "/" + url,
          //请求的后台接口
          method: "get",
          //get请求方式
          params
        }).then(({
          data: res
        }) => {
          if (res.success) {
            this.missionTotal = res.data.totalCount;
            this.$emit(this.searchInfo.origin !== "manage" ? "missionTotal" : "manageMissionTotal", {
              name: "mission",
              value: res.data.totalCount,
              origin: this.searchInfo.origin
            });
            resolve(res.data.list || []);
          }
        }).catch(() => {});
      });
    },
    cancelDel() {
      this.delData = null;
    },
    // 编辑删除分享
    chooseMenu($event, item) {
      if ($event === "delete") {
        // this.deleteData(item);
        this.delData = JSON.parse(JSON.stringify(item));
        this.$tipModal({
          visible: true,
          tipObj: {
            title: "",
            content: "Are you sure you want to delete this?",
            footer: [{
              name: "Cancel",
              type: undefined,
              query: "cancel",
              class: "default-button",
              fn: this.cancelDel
            }, {
              name: "Confirm",
              type: "primary",
              query: "confirmDelete",
              class: "primary-button",
              fn: () => this.deleteData(this.delData)
            }]
          }
        });
      } else if ($event === "edit") {
        this.edit(item);
      } else {
        this.shared(item);
      }
    },
    // 删除
    deleteData(item) {
      this.$http({
        url: "/api/mission/delete?missionId=" + item.id,
        //请求的后台接口
        method: "post" //get请求方式
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.getData(true);
          this.delData = null;
        }
      }).catch(() => {});
    },
    // 编辑
    edit(item) {
      this.$router.push({
        name: "CreateMission",
        query: {
          id: item.id
        }
      }).catch(() => {});
    },
    shared(item) {
      this.showReport = true;
      this.reportUrl = {
        url: location.origin + "/#/mission-detail?id=" + item.id + "&shared=true",
        data: {
          id: item.id,
          origin: "mission"
        }
      };
    },
    report($event) {
      if ($event !== "cancel") {
        this.postShared(this.reportUrl.data, $event);
      }
      this.showReport = false;
    },
    // 查看mission详情
    missionDetail(item) {
      this.$router.push({
        name: "MissionDetail",
        query: {
          id: item.id
        }
      }).catch(() => {});
    }
  },
  created() {
    const userid = sessionStorage.getItem("otherInfo");
    if (userid) {
      this.otherInfo = {
        id: userid
      };
    } else {
      this.otherInfo = JSON.parse(JSON.stringify(this.info));
    }
    this.getData(true);
  }
};