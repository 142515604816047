import { createApp } from "vue";
import LoadingAll from "@/components/LoadingAll";

const MyLoadingAll = {
  install(app) {
    // 配置此应用
    app.config.globalProperties.$loadingModal = function (options) {
      const container = document.createElement("div");
      let vm = createApp(LoadingAll, {
        ...options,
      });

      document.body.appendChild(container);
      vm.mount(container);
      return () => {
        if (vm) {
          vm.unmount();
          document.body.removeChild(container);
          vm = undefined;
        }
      };
    };
  },
};
export default MyLoadingAll;
