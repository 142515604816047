export default {
  name: "MissionLocationModal",
  props: ["location", "location_link", "location_address", "id"],
  data() {
    return {
      // 线上会议链接
      link: "",
      // 存整个地址数据,最后将这整个对象给父组件
      address: null,
      // 存下拉框地址数据id，无数据时值为default，主要为了样式
      address_id: "",
      // 新建地址数据
      place_id: "",
      main_text: "",
      detail: "",
      // 新建地址时提供给用户是否设为默认数据
      setDefault: false,
      // 历史地址下拉
      placeList: [],
      // 当前位置经纬度
      currentPosition: null,
      // 系统语言
      currentLau: "",
      // 当前具体地址
      currentAddress: null,
      loading: false,
      // 谷歌匹配地址下拉
      searchAddressList: [],
      // 用于中断网络请求
      cancel: null
    };
  },
  methods: {
    getPlaceList() {
      return new Promise(resolve => {
        this.$http({
          url: "/api/address/list",
          //请求的后台接口
          method: "get" //get请求方式
        }).then(({
          data: res
        }) => {
          if (res.success) {
            this.placeList = res.data;
            this.address_id = this.location_address?.id || "default";
            this.address = this.location_address;
            resolve(res.data);
          }
        }).catch(() => {});
      });
    },
    changeAddress($event) {
      this.address = this.placeList.find(e => e.id === $event);
      this.localtionModal("save");
    },
    changePlace($event) {
      const data = this.searchAddressList.find(e => e.place_id === $event);
      this.main_text = data.structured_formatting.main_text;
      this.detail = data.description;
    },
    localtionModal(type) {
      this.$emit("localtionModal", type === "cancel" ? null : {
        link: this.link,
        address: this.address
      });
    },
    addAddress() {
      if (!this.place_id) {
        this.$tipModal({
          visible: true,
          tipObj: {
            title: "",
            content: "Please choose the place",
            footer: [{
              name: "Close",
              type: undefined,
              query: "close",
              class: "default-button"
            }]
          }
        });
        return;
      }
      const params = {
        placeId: this.place_id,
        language: this.currentLau,
        tagDefault: this.setDefault ? "1" : undefined,
        detail: this.detail,
        place: this.main_text
      };
      this.$http({
        method: "post",
        url: `/api/address/create`,
        data: params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.address = res.data;
          this.localtionModal("save");
        }
      }).catch(() => {});
    },
    // 获取当前地址
    async getCurrentLocation() {
      // latitude纬度，纬度在前，经度在后
      const {
        latitude,
        longitude
      } = this.currentPosition;
      if (latitude !== 1.352083 && longitude !== 103.819836) {
        try {
          // 发起 HTTP 请求到谷歌地图 Geocoding API
          // const response = await fetch(
          //   `https://maps.googleapis.com/maps/api/geocode/json?latlng=32.0041,118.8021&key=AIzaSyAEM2LYHv5qDjL-8Wi7Id7jocDzYmICa6w&language=en`
          // );
          const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyAEM2LYHv5qDjL-8Wi7Id7jocDzYmICa6w&language=en`);

          // 解析 API 响应的 JSON 数据
          const data = await response.json();

          // 检查 API 是否返回了有效的结果
          if (data.results && data.results.length > 0) {
            // 获取逆地理编码后的地址描述
            const {
              address_components,
              formatted_address
            } = data.results[0];
            const place = [address_components.find(component => component.types.includes("country")).long_name, address_components.find(component => component.types.includes("administrative_area_level_1")).long_name, address_components.find(component => component.types.includes("locality")).long_name].join(", ");
            this.currentAddress = {
              longitude: longitude,
              latitude: latitude,
              place,
              detail: formatted_address
              // detail: formatted_address.replace(place, "").trim().slice(2),
            };
          } else {
            // 如果没有有效结果，将地址设为空
          }
        } catch (error) {
          // 如果发生错误，将地址设为空，并打印错误信息到控制台
        }
      }
    },
    handleSearch(value) {
      if (value) {
        if (this.cancel) {
          this.cancel();
        }
        const that = this;
        const CancelToken = this.$http.CancelToken;
        this.loading = true;
        const params = {
          input: value,
          location: !this.currentPosition ? "" : this.currentPosition?.latitude + "," + this.currentPosition?.longitude,
          radius: "",
          language: this.currentLau
        };
        this.$http({
          url: "/api/address/autoCompleteFromGoogleMap",
          //请求的后台接口
          method: "get",
          //get请求方式
          params,
          config: {
            disabledLoading: true
          },
          cancelToken: new CancelToken(function executor(c) {
            // 将axios的取消函数赋值给外部变量
            that.cancel = c;
          })
        }).then(({
          data: res
        }) => {
          if (res.success) {
            this.searchAddressList = res.data.predictions;
          } else {
            this.searchAddressList = [];
          }
          this.cancel = null;
          this.loading = false;
        }).catch(error => {
          // 请求已被取消
          // this.$http.isCancel(error)
        });
        // const request = {
        //   query: value,
        //   fields: ["name", "geometry"],
        // };
        // const service = new google.maps.places.PlacesService(
        //   document.createElement("div")
        // );
        // service.findPlaceFromQuery(request, (results, status) => {
        //   if (status === google.maps.places.PlacesServiceStatus.OK) {
        //     results.forEach((place) => {
        //     });
        //   }
        // });
      } else {
        this.searchAddressList = [];
      }
    }
  },
  created() {
    if (this.location !== "Online") {
      this.getPlaceList();
    } else {
      this.link = this.location_link;
    }
    // // 当前语言代码
    this.currentLau = navigator.language || navigator.userLanguage;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async position => {
        const {
          latitude,
          longitude
        } = position.coords;
        this.currentPosition = {
          latitude,
          longitude
        };
      });
    }
    // this.getCurrentLocation();
  }
};