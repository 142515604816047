// 引入 axios
import axios from "axios";

// 向外暴露 axios
const $http = {
  install(app) {
    const http = axios;
    let requestCount = 0; //当前正在请求的数量
    let close = null;
    const { $tipModal, $loadingModal, $loginModal } =
      app.config.globalProperties;

    http.interceptors.request.use(
      function (config) {
        if (!config?.config || !config?.config?.disabledLoading) {
          showLoading();
        }
        if (sessionStorage.getItem("user")) {
          config.headers["Authorization"] = JSON.parse(
            sessionStorage.getItem("user")
          ).token;
        }
        return config;
      },
      function (error) {
        // 处理请求错误
        hiddenLoading();
        return Promise.reject(error);
      }
    );
    // 响应拦截器
    http.interceptors.response.use(
      function (response) {
        hiddenLoading();
        // 报错提示
        if (!response.data.success) {
          $tipModal({
            visible: true,
            tipObj: {
              title: "",
              content: response.data.msg,
              footer: [
                {
                  name: "Close",
                  type: undefined,
                  query: "close",
                  class: "default-button",
                },
              ],
            },
          });
        }
        return response;
      },
      function (error) {
        hiddenLoading();
        if (error.response.status === 401) {
          // 登录信息失效提示
          $tipModal({
            visible: true,
            tipObj: {
              title: "",
              content:
                "Your login information is no longer valid, please log in again",
              footer: [
                {
                  name: "Ok",
                  type: undefined,
                  query: "login",
                  class: "default-button",
                  fn: () =>
                    $loginModal({
                      visible: true,
                      next: null,
                    }),
                },
              ],
            },
          });
        } else {
          // 报错提示
          $tipModal({
            visible: true,
            tipObj: {
              title: "",
              content: error.message,
              footer: [
                {
                  name: "Close",
                  type: undefined,
                  query: "close",
                  class: "default-button",
                },
              ],
            },
          });
        }
        // Message.error(error.message);
        return Promise.reject(error);
      }
    );

    function showLoading() {
      if (requestCount === 0) {
        close = $loadingModal({
          visible: true,
        });
      }
      requestCount++;
    }

    function hiddenLoading() {
      if (requestCount <= 0) return;
      requestCount--;
      if (requestCount === 0) {
        close();
      }
    }
    app.config.globalProperties.$http = http;
  },
};
export default $http;
