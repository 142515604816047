import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Back = _resolveComponent("Back");
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_button = _resolveComponent("el-button");
  const _component_CommentSingleAssign = _resolveComponent("CommentSingleAssign");
  const _component_a_modal = _resolveComponent("a-modal");
  return _openBlock(), _createBlock(_component_a_modal, {
    visible: true,
    "hide-title": true,
    closable: false,
    footer: false,
    "modal-class": "comment-all-assign"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_button, {
      circle: "",
      type: "primary",
      class: "back primary-button",
      onClick: _withModifiers($options.back, ["stop"])
    }, {
      default: _withCtx(() => [_createVNode(_component_el_icon, null, {
        default: _withCtx(() => [_createVNode(_component_Back)]),
        _: 1
      })]),
      _: 1
    }, 8, ["onClick"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list, item => {
      return _openBlock(), _createBlock(_component_CommentSingleAssign, {
        key: item.id,
        obj: {
          'id': item.id,
          'origin': 'assignment'
        }
      }, null, 8, ["obj"]);
    }), 128))]),
    _: 1
  });
}