import "core-js/modules/es.array.push.js";
// import picker compopnent
import EmojiPicker from "vue3-emoji-picker";
import "vue3-emoji-picker/css";
import SharedModal from "../components/SharedModal";
export default {
  name: "ClubDetail",
  components: {
    //注册组件，不能全局挂载
    EmojiPicker,
    SharedModal
  },
  data() {
    return {
      show: false,
      // 存储mission列表
      mission: [],
      missionTotal: 0,
      // 加载中
      loading: true,
      missionIndex: 1,
      emojiVisble: false,
      comment: "",
      id: "",
      detail: null,
      postDetail: null,
      commentData: [],
      commentTotal: 0,
      // 加载中
      commentIndex: 1,
      commentLoading: true,
      replayObj: null,
      reportUrl: null,
      showReport: false
    };
  },
  computed: {
    // 无法展示更多
    noMore() {
      return this.mission.length === this.missionTotal;
    },
    // 加载中防抖节流
    disabled() {
      return this.loading;
    },
    noMoreComment() {
      return this.commentData.length === this.commentTotal;
    },
    // 加载中防抖节流
    disabledComment() {
      return this.commentLoading;
    }
  },
  methods: {
    back() {
      this.$router.back();
    },
    showDetail(item) {
      this.show = !this.show;
      this.postDetail = item;
      if (item) {
        this.getCommentData(true);
      }
    },
    load() {
      if (this.missionIndex * 10 < this.missionTotal && !this.show) {
        this.loading = true;
        this.missionIndex++;
        this.getData(false);
      }
    },
    async getData(reset) {
      if (reset) {
        this.mission = [];
        this.missionIndex = 1;
      }
      const data = await this.getAllData();
      // data.forEach((element) => {
      //   element.avatar = element.memberAvatarList.slice(0, 4);
      //   const num = element.participantsCount - 4;
      //   if (num > 0) {
      //     element.avatarNum = num > 100 ? "99+" : "+" + num;
      //   }
      // });
      this.mission = [...this.mission, ...data];
      this.loading = false;
      return true;
    },
    // filter为all
    getAllData() {
      return new Promise(resolve => {
        const params = {
          clubId: this.id,
          timeZone: this.$timezone,
          title: "",
          page: this.missionIndex,
          size: 10,
          tagDelete: "0"
        };
        this.$http({
          url: "/api/post/list",
          //请求的后台接口
          method: "get",
          //get请求方式
          params
        }).then(({
          data: res
        }) => {
          if (res.success) {
            this.missionTotal = res.data.totalCount;
            const data = res.data.list.map(e => {
              return {
                ...e,
                setTimeout: null,
                likedByMeOrigin: e.likedByMe
              };
            });
            resolve(data);
          }
        }).catch(() => {});
      });
    },
    changeEmoji($event) {
      this.emojiVisble = false;
      this.comment += $event.i;
    },
    getDetail() {
      const params = {
        timeZone: this.$timezone,
        clubId: this.id
      };
      this.$http({
        url: "/api/club/detail",
        //请求的后台接口
        method: "get",
        //get请求方式
        params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.detail = res.data;
          this.detail.avatar = this.detail.memberAvatarList.slice(0, 10);
          const num = this.detail.followCount - 10;
          if (num > 0) {
            this.detail.avatarNum = num > 100 ? "99+" : "+" + num;
          }
        }
      }).catch(() => {});
    },
    // 加入mission
    join(e) {
      if (this.isLogin(e)) {
        return;
      }
      this.$http.post(`/api/club/${!this.detail.followState ? "join" : "leave"}?clubId=` + this.detail.id).then(({
        data: res
      }) => {
        if (res.success) {
          this.getDetail();
        }
      }).catch(() => {});
    },
    createPost(e) {
      if (this.isLogin(e)) {
        return;
      }
      if (!this.detail.followState) {
        this.$tipModal({
          visible: true,
          tipObj: {
            title: "",
            content: "Please join the club first",
            footer: [{
              name: "Close",
              type: undefined,
              query: "close",
              class: "default-button"
            }]
          }
        });
        // this.$message.error("Please join the club first");
        return;
      }
      this.$router.push({
        name: "CreatePost",
        query: {
          clubId: this.detail.id
        }
      }).catch(() => {});
    },
    loadComment() {
      if (this.commentIndex * 10 < this.commentTotal && this.show) {
        this.commentLoading = true;
        this.commentIndex++;
        this.getCommentData(false);
      }
    },
    async getCommentData(reset) {
      if (reset) {
        this.commentData = [];
        this.commentIndex = 1;
      }
      const data = await this.getComment();
      this.commentData = [...this.commentData, ...data];
      this.commentLoading = false;
      return true;
    },
    getComment() {
      return new Promise(resolve => {
        const params = {
          objId: this.postDetail.id,
          timeZone: this.$timezone,
          page: this.commentIndex,
          size: 10
        };
        this.$http({
          url: "/api/comment/listMain",
          //请求的后台接口
          method: "get",
          //get请求方式
          params
        }).then(({
          data: res
        }) => {
          if (res.success) {
            this.commentTotal = res.data.totalCount;
            const data = res.data.list.map(e => {
              return {
                ...e,
                children: [],
                pageIndex: 0,
                setTimeout: null,
                likedByMeOrigin: e.likedByMe
              };
            });
            resolve(data);
          }
        }).catch(() => {});
      });
    },
    expand(item) {
      item.pageIndex++;
      const params = {
        parentId: item.id,
        timeZone: this.$timezone,
        page: this.commentIndex,
        size: 10
      };
      this.$http({
        url: "/api/comment/listReply",
        //请求的后台接口
        method: "get",
        //get请求方式
        params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          // item.replyCount = res.data.totalCount;
          const data = res.data.list.map(e => {
            return {
              ...e,
              setTimeout: null,
              likedByMeOrigin: e.likedByMe
            };
          });
          item.children.push(...data);
        }
      }).catch(() => {});
    },
    sendComment(event) {
      event.preventDefault();
      const replayData = JSON.parse(JSON.stringify(this.replayObj));
      if (this.comment) {
        const params = {
          content: this.comment,
          bisId: replayData ? undefined : this.postDetail.id,
          parentId: replayData ? replayData.item.id : undefined,
          toUserId: replayData ? replayData.item.fromUserId : this.postDetail.userId
        };
        const url = replayData ? "api/comment/reply" : `api/post/sendComment`;
        this.$http.post(`/${url}`, params).then(({
          data: res
        }) => {
          const responseData = {
            ...res.data,
            likeCount: 0,
            replyCount: 0,
            timeDiff: "1 minute ago",
            fromUserName: this.info.userName,
            fromUserAvatar: this.info.avatar,
            children: [],
            pageIndex: 0
          };
          if (res.success) {
            if (!replayData) {
              this.commentData.unshift(responseData);
            } else {
              if (!this.commentData[replayData.index].children.length) {
                this.commentData[replayData.index].replyCount++;
              } else {
                this.commentData[replayData.index].children.unshift(responseData);
              }
            }
            this.comment = "";
            this.postDetail.commentCount++;
          }
        }).catch(() => {});
      }
    },
    replay(item, index) {
      if (this.isLogin()) {
        return;
      }
      this.replayObj = {
        index,
        item
      };
      this.$refs.input.focus();
    },
    chooseMenu($event, item) {
      if ($event === "share") {
        this.shared(item, "post");
      } else {
        if (this.isLogin()) {
          return;
        }
        this.$tipModal({
          visible: true,
          tipObj: {
            title: "",
            content: "Report successful",
            footer: [{
              name: "Close",
              type: undefined,
              query: "close",
              class: "default-button"
            }]
          }
        });
        // this.$message.success("Report successful");
      }
    },
    shared(item, type) {
      this.showReport = true;
      if (type === "detail") {
        this.reportUrl = {
          url: location.origin + "/#/club-detail?id=" + item.id + "&shared=true",
          data: {
            id: item.id,
            origin: "club"
          }
        };
      } else {
        this.reportUrl = {
          url: location.origin + "/#/?share=" + JSON.stringify({
            id: item.id,
            origin: "post"
          }) + "&shared=true",
          data: {
            id: item.id,
            origin: "post"
          }
        };
      }
    },
    report($event) {
      if ($event !== "cancel") {
        this.postShared(this.reportUrl.data, $event);
      }
      this.showReport = false;
    },
    toOther(item, query) {
      if (this.isLogin()) {
        return;
      }
      if (item[query] !== this.info.id) {
        this.$router.push({
          name: "FollowOther",
          query: {
            userId: item[query]
          }
        });
      } else {
        this.$router.push({
          name: "PersonalManage",
          query: {
            index: "3"
          }
        });
      }
    },
    dianzan(e, item, type) {
      if (this.isLogin(e)) {
        return;
      }
      this.clickLike(item, type);
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.getDetail();
    this.getData(true);
  }
};