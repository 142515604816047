import "core-js/modules/es.array.push.js";
import MissionData from "./MissionData.vue";
import ClubData from "./ClubData.vue";
import PersonAssign from "./PersonAssign.vue";
export default {
  name: "MissionClub",
  props: ["index"],
  components: {
    MissionData,
    ClubData,
    PersonAssign
  },
  data() {
    return {
      // 列表展示方式-card,list
      showType: "card",
      menu: [{
        title1: "All",
        title2: "mission",
        addTitle: "New mission",
        total: 0,
        search: {
          filter: "All",
          query: "",
          origin: "all"
        },
        filterList: [{
          name: "All"
        }, {
          name: "Popular",
          divided: true
        }, {
          name: "Past"
        }, {
          name: "Free",
          divided: true
        }, {
          name: "Charge"
        }, {
          name: "Reward"
        }],
        ref: "missionData"
      }, {
        title1: "All",
        title2: "club",
        addTitle: "New club",
        total: 0,
        search: {
          filter: "All",
          query: "",
          origin: "all"
        },
        filterList: [{
          name: "All"
        }, {
          name: "Popular",
          divided: true
        }],
        ref: "clubData"
      }, {
        title1: "My",
        title2: "drafts",
        addTitle: "Submit submissions",
        total: 0,
        search: {
          filter: "All",
          query: "",
          origin: "draft"
        },
        ref: "assignData"
      }]
    };
  },
  computed: {
    // 根据导航栏定位位置展示文字
    menuIndex() {
      return this.index === "2-1" ? 0 : this.index === "2-2" ? 1 : 2;
    }
  },
  methods: {
    // 改变数据展示方式
    changeShowType(type) {
      this.showType = type;
      // this.getData();
    },
    // 选中filter菜单
    chooseFilter($event) {
      this.menu[this.menuIndex].search.filter = $event;
      this.getData();
    },
    getData() {
      this.$refs[`${this.menu[this.menuIndex].ref}`].getData(true);
    },
    // 获取查询总数
    receiveTotal($event) {
      this.menu[this.menuIndex].total = $event.value;
    },
    create() {
      if (this.menuIndex === 0) {
        this.$router.push({
          name: "CreateMission"
        }).catch(() => {});
      } else if (this.menuIndex === 1) {
        this.$router.push({
          name: "CreateClub"
        }).catch(() => {});
      }
    }
  },
  created() {}
};