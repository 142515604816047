import "core-js/modules/es.array.push.js";
export default {
  name: "WalletTransfersSuccess",
  data() {
    return {
      id: "",
      type: ""
    };
  },
  computed: {},
  watch: {},
  methods: {
    getTransactionDetail() {
      const params = {
        billId: this.id,
        timeZone: this.$timezone
      };
      this.$http({
        url: "/api/account/getTransactionDetail",
        //请求的后台接口
        method: "get",
        //get请求方式
        params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.type = res.data.billTarget === 'WITHDRAW' ? 'confirming' : res.data.tagSuccess === '0' ? 'success' : 'error';
        }
      }).catch(() => {});
    },
    // 返回
    back() {
      this.$router.push({
        name: "WalletTransfers"
      }).catch(() => {});
    }
  },
  created() {
    this.id = this.$route.query?.payId || "";
    this.getTransactionDetail();
  }
};