import country from "@/assets/utils/country";
// import { ElDialog } from "element-plus";
// import "element-plus/es/components/dialog/style/css";
export default {
  name: "LoginComponents",
  // components: {
  //   ElDialog,
  // },
  props: ["visible", "next", "particle", "http", "close", "getInfoJudge"],
  methods: {
    login(type) {
      this.particle.auth.login({
        preferredAuthType: type,
        supportAuthTypes: "facebook,google,email,apple",
        socialLoginPrompt: "select_account"
      }).then(info => {
        if (info.token) {
          sessionStorage.setItem("user", JSON.stringify(info));
          Promise.all([this.postLogin(info)]).then(() => {
            if (this.next) {
              this.getInfoJudge(this.http, this.particle).then(() => {
                this.next();
              });
            } else {
              location.reload();
            }
            this.close();
          });
        }
      }).catch(() => {});
    },
    postLogin(info) {
      return new Promise(resolve => {
        const params = {
          uuid: info.uuid,
          token: info.token
        };
        this.http.post("/api/auth/loginSpecial", params).then(({
          data: res
        }) => {
          if (res.success) {
            if (res.data.region) {
              res.data.countryImg = require("../assets/country/" + country.find(e => e.value === res.data.region).img);
            }
            sessionStorage.setItem("info", JSON.stringify(res.data));
            resolve();
          }
        }).catch(() => {});
      });
    }
    // getAuth() {
    //   return new Promise((resolve) => {
    //     this.http({
    //       url: "/api/auth/selfInfo", //请求的后台接口
    //       method: "get", //get请求方式
    //     })
    //       .then(({ data: res }) => {
    //         if (res.success) {
    //           if (res.data.region) {
    //             res.data.countryImg = require("../assets/country/" +
    //               country.find((e) => e.value === res.data.region).img);
    //           }
    //           sessionStorage.setItem("info", JSON.stringify(res.data));
    //           resolve();
    //         }
    //       })
    //       .catch(() => {});
    //   });
    // },
  }
};