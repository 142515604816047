import "core-js/modules/es.array.push.js";
import SelfPicker from "@/components/SelfPicker.vue";
import MissionNumModal from "@/components/MissionNumModal.vue";
import MissionLocationModal from "@/components/MissionLocationModal.vue";
import formattedTimeZones from "@/assets/utils/format-time-zone.js";
import { GoogleMap, Marker } from "vue3-google-map";
import SharedModal from "@/components/SharedModal";
export default {
  name: "CreateMission",
  components: {
    SelfPicker,
    MissionNumModal,
    MissionLocationModal,
    GoogleMap,
    Marker,
    SharedModal
  },
  data() {
    return {
      steps: [],
      activeIndex: 0,
      club: "default",
      // default用于选择框可以展示标头
      clubLogo: "",
      clubName: "",
      clubList: [],
      fileList: [],
      title: "",
      description: "",
      // 限制Participants：3-100
      participants: 3,
      // select弹窗
      missionNumVisible: false,
      // Select mission billing
      select: "Free",
      selectMoney: 0,
      selectRemark: "",
      selectDivision: null,
      // submission-limit开关
      submissionLimit: false,
      submissionLimitValue: 1,
      // content-limit开关
      contentLimit: false,
      contentLimitValue: "Unlimited",
      // 时间
      start: "",
      end: "",
      startTime: "",
      endTime: "",
      selectedTimezone: this.moment.tz.guess(),
      // 获取用户当前时区
      timezones: formattedTimeZones,
      // 获取所有时区
      // 地址or链接
      location: "",
      location_link: "",
      location_address: null,
      // location弹窗
      missionLocationVisible: false,
      // 是否允许上传图片
      imageAccess: false,
      id: "",
      success: "",
      reportUrl: null,
      showReport: false
    };
  },
  computed: {
    startShow() {
      return this.start ? this.moment(this.start, "yyyy-MM-DD").format("dddd,MMM DD yyyy") : "start date";
    },
    endShow() {
      return this.end ? this.moment(this.end, "yyyy-MM-DD").format("dddd,MMM DD yyyy") : "end date";
    },
    startTimeShow() {
      return this.startTime ? this.moment(this.startTime, "yyyy-MM-DD hh:mm:ss").format("hh:mm A") : "start time";
    },
    endTimeShow() {
      return this.endTime ? this.moment(this.endTime, "yyyy-MM-DD hh:mm:ss").format("hh:mm A") : "end time";
    }
  },
  watch: {
    selectedTimezone(newValue) {
      if (this.startTime && this.start) {
        const startTimeFmt = this.moment(new Date(this.startTime)).tz(newValue).format("HH:mm:ss");
        const startDate = this.moment(this.start + " " + startTimeFmt, "yyyy-MM-DD HH:mm:ss").tz(newValue);
        this.start = startDate.format("yyyy-MM-DD");
        this.startTime = startDate.format("yyyy-MM-DD HH:mm:ss");
      }
      if (this.endTime && this.end) {
        const endTimeFmt = this.moment(new Date(this.endTime)).tz(newValue).format("HH:mm:ss");
        const endDate = this.moment(this.end + " " + endTimeFmt, "yyyy-MM-DD HH:mm:ss").tz(newValue);
        this.end = endDate.format("yyyy-MM-DD");
        this.endTime = endDate.format("yyyy-MM-DD HH:mm:ss");
      }
    }
  },
  methods: {
    // 获取选中club的信息，预览展示用
    changeClub($event) {
      const {
        name,
        imageUrl
      } = this.clubList.find(e => e.id === $event);
      this.clubLogo = imageUrl;
      this.clubName = name;
    },
    // 获取club列表
    getClubData() {
      const params = {
        userId: this.info.id,
        timeZone: this.$timezone,
        name: "",
        page: 1,
        size: 99999
      };
      this.$http({
        url: "/api/club/listFollowed",
        //请求的后台接口
        method: "get",
        //get请求方式
        params
      }).then(({
        data: res
      }) => {
        if (res.success) {
          this.clubList = res.data.list;
        }
      }).catch(() => {});
    },
    missionModal($event) {
      this.missionNumVisible = false;
      if ($event) {
        this.selectMoney = $event.num;
        this.selectRemark = $event.remark;
        this.selectDivision = $event.division;
      }
    },
    localtionModal($event) {
      this.missionLocationVisible = false;
      if ($event) {
        this.location_link = $event.link;
        this.location_address = $event.address;
      }
    },
    backStep() {
      this.activeIndex--;
    },
    // 上传文件成功后将其添加至fileList
    upload(file) {
      // this.fileList.push(file);
      const formData = new FormData();
      formData.append("file", file.file);
      this.$http.post("/api/file/fileUpload", formData).then(({
        data: res
      }) => {
        if (res.success) {
          this.fileList = [{
            name: res.data.fileName,
            url: res.data.url,
            // 鼠标悬浮图片上面出现删除按钮
            imageDelete: false
          }];
        }
      }).catch(() => {});
    },
    beforeUpload(file) {
      if (file.type.indexOf("image") > -1) {
        return true;
      } else {
        return false;
      }
    },
    // Select mission billing
    changeSelect(type) {
      this.selectMoney = 0;
      this.selectRemark = "";
      this.selectDivision = null;
      this.select = type;
    },
    // 改变时间
    changeDate(value) {
      this.start = value.start;
      this.end = value.end;
    },
    // 返回
    back() {
      if (this.activeIndex === 0 || this.success) {
        this.$router.back();
      } else {
        this.activeIndex--;
      }
      // this.$router.push({
      //   name: "PersonalManage",
      //   query: {
      //     index: "2-1",
      //   },
      // });
    },
    tip(content) {
      this.$tipModal({
        visible: true,
        tipObj: {
          title: "",
          content,
          footer: [{
            name: "Close",
            type: undefined,
            query: "close",
            class: "default-button"
          }]
        }
      });
    },
    async next() {
      if (this.steps[this.activeIndex] === "Basic info") {
        if (!this.club || this.club === "default") {
          this.$tipModal({
            visible: true,
            tipObj: {
              title: "",
              content: "Please choose a club. If you haven't joined a club before, you can create a new one",
              footer: [{
                name: "Close",
                type: undefined,
                query: "close",
                class: "default-button"
              }, {
                name: "Create a club",
                type: "primary",
                query: "createClub",
                class: "primary-button",
                fn: () => {
                  sessionStorage.setItem("missionInfo", JSON.stringify({
                    title: this.title,
                    description: this.description,
                    selectedTimezone: this.selectedTimezone,
                    participants: this.participants,
                    submissionLimitValue: this.submissionLimitValue,
                    submissionLimit: this.submissionLimit,
                    contentLimitValue: this.contentLimitValue,
                    contentLimit: this.contentLimit,
                    start: this.start,
                    startTime: this.startTime,
                    end: this.end,
                    endTime: this.endTime,
                    club: this.club,
                    select: this.select,
                    selectMoney: this.selectMoney,
                    selectRemark: this.selectRemark,
                    selectDivision: this.selectDivision,
                    location: this.location,
                    location_link: this.location_link,
                    location_address: this.location_address,
                    clubLogo: this.clubLogo,
                    clubName: this.clubName
                  }));
                  this.$router.push({
                    name: "CreateClub"
                  }).catch(() => {});
                }
              }]
            }
          });
          return;
        }
        if (!this.title.trim()) {
          this.tip("Please maintain the title");
          return;
        }
        if (!this.description.trim()) {
          this.tip("Please maintain the description");
          return;
        }
        if (!this.fileList.length) {
          this.tip("Please maintain the cover image");
          return;
        }
        if (this.select === "Reward" && (!this.selectMoney || !this.selectRemark || !this.selectDivision)) {
          this.tip("Because the billing option is reward, please maintain the price, goal, and proportion.");
          return;
        }
        if (this.select === "Charge" && !this.selectMoney) {
          this.tip("Because the billing option is charge, please maintain the price.");
          return;
        }
        if (this.select === "Reward" && this.participants * this.selectMoney > 5000) {
          this.tip("The ticket price * maximum number of people should not exceed $5000 SGD.");
          return;
        }
        if (this.id) {
          await this.saveOne();
        }
        this.activeIndex++;
      } else if (this.steps[this.activeIndex] === "Address and Date") {
        if (!this.start || !this.end || !this.startTime || !this.endTime) {
          this.tip("Please maintain the start and end times");
          return;
        }
        if (!this.location) {
          this.tip("Please maintain the mission location");
          return;
        }
        if (!this.location && !this.location_link) {
          this.tip("Because the mission location option is online, please maintain the virtual link.");
          return;
        }
        if (!this.location && !this.location_address) {
          this.tip("Because the mission location option is offline, please maintain the offline location.");
          return;
        }
        if (this.id) {
          await this.saveTwo();
        }
        this.activeIndex++;
      } else if (this.steps[this.activeIndex] === "Preview") {
        if (!this.id) {
          await this.save();
        }
        sessionStorage.removeItem("missionInfo");
        this.success = this.steps.length < 3 ? "Modified Successful" : "Created Successful";
      }
    },
    share() {
      this.showReport = true;
      this.reportUrl = {
        url: location.origin + "/#/mission-detail?id=" + this.id + "&shared=true",
        data: {
          id: this.id,
          origin: "mission"
        }
      };
    },
    report() {
      this.showReport = false;
    },
    getDetail() {
      const params = {
        timeZone: this.$timezone,
        missionId: this.id
      };
      this.$http({
        url: "/api/mission/detail",
        //请求的后台接口
        method: "get",
        //get请求方式
        params
      }).then(async ({
        data: res
      }) => {
        if (res.success) {
          const {
            title,
            description,
            timeZone,
            imageUrl,
            participantsLimit,
            submitTimesLimit,
            characterNumLimit,
            startDate,
            endDate,
            officialClubId,
            joinReward,
            joinRewardGoal,
            joinCharge,
            onlineInfo,
            addressId,
            officialClubLogo,
            officialClubName
          } = res.data;
          this.title = title;
          this.description = description;
          this.selectedTimezone = timeZone;
          this.fileList = [{
            name: "mission.png",
            url: imageUrl,
            // 鼠标悬浮图片上面出现删除按钮
            imageDelete: false
          }];
          this.participants = participantsLimit;
          this.submissionLimitValue = submitTimesLimit;
          this.submissionLimit = Boolean(submitTimesLimit);
          this.contentLimitValue = characterNumLimit || "Unlimited";
          this.contentLimit = Boolean(characterNumLimit);
          const startDateFmt = this.moment(new Date(startDate)).tz(this.selectedTimezone);
          const endDateFmt = this.moment(new Date(endDate)).tz(this.selectedTimezone);
          this.start = startDateFmt.format("yyyy-MM-DD");
          this.startTime = startDateFmt.format("yyyy-MM-DD HH:mm:ss");
          this.end = endDateFmt.format("yyyy-MM-DD");
          this.endTime = endDateFmt.format("yyyy-MM-DD HH:mm:ss");
          this.club = officialClubId;
          this.select = joinRewardGoal ? "Reward" : joinCharge ? "Charge" : "Free";
          this.selectMoney = this.select === "Free" ? 0 : joinCharge;
          this.selectRemark = this.select !== "Reward" ? "" : joinRewardGoal;
          this.selectDivision = this.select !== "Reward" ? null : joinReward;
          this.location = onlineInfo ? "Online" : "Offline";
          this.location_link = onlineInfo ? onlineInfo : "";
          const placeList = await this.getPlaceList();
          this.location_address = onlineInfo ? "" : placeList.find(e => e.id + "" === addressId);
          this.clubLogo = officialClubLogo;
          this.clubName = officialClubName;
        }
      }).catch(() => {});
    },
    getPlaceList() {
      return new Promise(resolve => {
        this.$http({
          url: "/api/address/list",
          //请求的后台接口
          method: "get" //get请求方式
        }).then(({
          data: res
        }) => {
          if (res.success) {
            resolve(res.data);
          }
        }).catch(() => {});
      });
    },
    saveOne() {
      return new Promise(resolve => {
        const params = {
          id: this.id,
          title: this.title.trim(),
          description: this.description.trim(),
          imageUrl: this.fileList[0].url,
          characterNumLimit: this.contentLimitValue === "Unlimited" ? undefined : this.contentLimitValue,
          // imageUploadAccess: this.imageAccess ? "0" : "1",
          imageUploadAccess: "1",
          submitTimesLimit: this.submissionLimit ? this.submissionLimitValue : undefined
        };
        this.$http.post("/api/mission/updateOne", params).then(({
          data: res
        }) => {
          if (res.success) {
            resolve();
          }
        }).catch(() => {});
      });
    },
    saveTwo() {
      return new Promise(resolve => {
        const params = {
          id: this.id,
          startDate: this.start + this.moment(new Date(this.startTime)).tz(this.selectedTimezone).format(" HH:mm:00"),
          endDate: this.end + this.moment(new Date(this.endTime)).tz(this.selectedTimezone).format(" HH:mm:00"),
          timeZone: this.selectedTimezone,
          addressId: this.location === "Online" ? undefined : this.location_address?.id,
          onlineInfo: this.location === "Online" ? this.location_link : undefined
        };
        this.$http.post("/api/mission/updateAddressPlace", params).then(({
          data: res
        }) => {
          if (res.success) {
            resolve();
          }
        }).catch(() => {});
      });
    },
    save() {
      return new Promise(resolve => {
        const params = {
          title: this.title.trim(),
          description: this.description.trim(),
          imageUrl: this.fileList[0].url,
          participantsLimit: this.participants,
          creatorId: this.info.id,
          startDate: this.start + this.moment(new Date(this.startTime)).tz(this.selectedTimezone).format(" HH:mm:00"),
          endDate: this.end + this.moment(new Date(this.endTime)).tz(this.selectedTimezone).format(" HH:mm:00"),
          timeZone: this.selectedTimezone,
          addressId: this.location === "Online" ? undefined : this.location_address?.id,
          onlineInfo: this.location === "Online" ? this.location_link : undefined,
          joinReward: this.select !== "Reward" ? undefined : +this.selectDivision,
          joinCharge: this.select === "Free" ? undefined : this.selectMoney,
          joinRewardGoal: this.select !== "Reward" ? undefined : this.selectRemark,
          characterNumLimit: this.contentLimitValue === "Unlimited" ? undefined : this.contentLimitValue,
          // imageUploadAccess: this.imageAccess ? "0" : "1",
          imageUploadAccess: "1",
          submitTimesLimit: this.submissionLimit ? this.submissionLimitValue : undefined,
          officialClubId: this.club
        };
        this.$http.post("/api/mission/create", params).then(({
          data: res
        }) => {
          if (res.success) {
            this.id = res.data.id;
            resolve();
          }
        }).catch(() => {});
      });
    }
  },
  created() {
    this.id = this.$route.query?.id || "";
    this.getClubData();
    if (this.id) {
      this.getDetail();
    } else {
      const date = this.moment(new Date()).tz(this.selectedTimezone);
      this.start = this.end = date.format("yyyy-MM-DD");
      this.startTime = this.endTime = date.format("yyyy-MM-DD HH:mm:ss");
    }
    if (this.id) {
      this.steps = [this.$route.query?.editPage, "Preview"];
    } else {
      this.steps = ["Basic info", "Address and Date", "Preview"];
      const missionInfo = JSON.parse(sessionStorage.getItem("missionInfo"));
      for (let key in missionInfo) {
        this[key] = missionInfo[key];
      }
    }
  }
};