import {createApp} from 'vue'
import TipModal from '@/components/TipModal'

const MyTipModal = {
  install(app) {
    // 配置此应用
    app.config.globalProperties.$tipModal = function(options) {
      const container = document.createElement('div')
      let vm = createApp(
        TipModal,
        {
          ...options,
          close: () => {
            if (vm) {
              vm.unmount();
              document.body.removeChild(container);
              vm = undefined;
            }
          },
        }
      )
     
      document.body.appendChild(container)
      vm.mount(container)
  }  
  }
}
export default MyTipModal