import { createApp } from "vue";
import App from "./App.vue";
import "@/assets/iconfont/iconfont.css"; // 引入iconfont
import "@/assets/iconfont/iconfont.js"; // 引入iconfont
import moment from "moment"; // 格式化时间
import "moment-timezone"; // 时区控件
import { ParticleNetwork, WalletEntryPosition } from "@particle-network/auth";
import { Polygon } from "@particle-network/chains";
import $router from "@/router";
import $http from "@/assets/utils/http.js";
// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
import likeMixin from "@/assets/utils/like-mixin";
import MyLoginComponents from "@/assets/utils/global-modal/login-modal";
import MyTipModal from "@/assets/utils/global-modal/tip-modal";
import MyLoadingAll from "@/assets/utils/global-modal/loading-modal";
import "@/assets/style/index.scss";
import UserIcon from "./components/UserIcon";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import VueClipboard from "vue-clipboard2"; // 复制功能
import infiniteScroll from "vue-infinite-scroll";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { createPinia } from "pinia";
const app = createApp(App);

app.component(UserIcon.name, UserIcon);
// app.use(ElementPlus);

app.config.globalProperties.$particle = null;
app.config.globalProperties.$particle = new ParticleNetwork({
  projectId: process.env.VUE_APP_PROJECT_ID,
  clientKey: process.env.VUE_APP_CLIENT_KEY,
  appId: process.env.VUE_APP_APP_ID,
  // chainName: Ethereum.name, //optional: current chain name, default Ethereum.
  // chainId: Ethereum.id, //optional: current chain id, default 1.
  wallet: {
    //optional: by default, the wallet entry is displayed in the bottom right corner of the webpage.
    displayWalletEntry: false, //show wallet entry when connect particle.
    defaultWalletEntryPosition: WalletEntryPosition.BR, //wallet entry position
    uiMode: "light",
    supportChains: [Polygon], // optional: web wallet support chains.
    customStyle: {}, //optional: custom wallet style
  },
});
app.config.globalProperties.moment = moment; // 使其可以全局格式化时间
app.config.globalProperties.$timezone = moment.tz.guess(); // 使其可以全局格式化时间
app.use(MyTipModal);
app.use(MyLoadingAll);
app.use(MyLoginComponents);
app.use(VueClipboard);
app.use(infiniteScroll);
const pinia = createPinia();
app.use(pinia);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

app.mixin(likeMixin);
app.use($http);
app.use($router);

app.mount("#app");
